import { GetNotificationSchedule } from '@store/notifications/features/notification-schedule/get-notification-schedule/get-notification-schedule';
import { UpdateNotificationSchedule } from '@store/notifications/features/notification-schedule/update-notification-schedule/update-notification-schedule';
import { all, takeLatest } from 'redux-saga/effects';

export function* notificationsSagas() {
  yield all([
    takeLatest(GetNotificationSchedule.action, GetNotificationSchedule.saga),
    takeLatest(UpdateNotificationSchedule.action, UpdateNotificationSchedule.saga),
  ]);
}
