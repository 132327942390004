import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ILogInInfo, LogInProviderType } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export class PlatformAuth {
  static get action() {
    return createDeferredAction<LogInProviderType>('profile/GOOGLE_REAUTH');
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof PlatformAuth.action>): SagaIterator {
      try {
        const { data } = PlatformAuth.httpRequest.call(
          yield call(() => PlatformAuth.httpRequest.generator(payload)),
        );

        const { logInUrl, requestId } = data;

        meta?.deferred.resolve({ logInUrl, requestId });
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ILogInInfo>, LogInProviderType>(
      (provider) => replaceInUrl(MAIN_API.GOOGLE_REAUTH, ['provider', provider]),
      HttpRequestMethod.Get,
    );
  }
}
