import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { IEditPushNotificationCommand } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { IMeetingsState } from '../../types';

import { EditMeetingNotificationFailure } from './edit-notification-failure';
import { EditMeetingNotificationSuccess } from './edit-notification-success';

export class EditMeetingNotification {
  static get action() {
    return createDeferredAction<IEditPushNotificationCommand>('meetings/EDIT_NOTIFICATION');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.editMeetingNotificationPending = true;
      return draft;
    };
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof EditMeetingNotification.action>): SagaIterator {
      const { meetingId, enabled } = payload;

      try {
        EditMeetingNotification.httpRequest.call(
          yield call(() => EditMeetingNotification.httpRequest.generator(payload)),
        );

        yield put(
          EditMeetingNotificationSuccess.action({
            meetingId: meetingId as number,
            notificationEnabled: enabled as boolean,
          }),
        );
        meta?.deferred.resolve(payload);
      } catch (e: any) {
        yield put(EditMeetingNotificationFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, IEditPushNotificationCommand>(
      MAIN_API.EDIT_MEETING_NOTIFICATION,
      HttpRequestMethod.Put,
    );
  }
}
