import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import {
  IUpdateMeetingRequest,
  IUpdateMeetingResponse,
  UpdateMeetingResult,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { IMeetingsState } from '../../types';

import { EditMeetingSuccess } from './edit-meeting-success';

export class EditMeeting {
  static get action() {
    return createDeferredAction<IUpdateMeetingRequest>('meetings/EDIT_MEETING');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.editMeetingPending = true;
      return draft;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof EditMeeting.action>): SagaIterator {
      try {
        const { data } = EditMeeting.httpRequest.call(
          yield call(() => EditMeeting.httpRequest.generator(payload)),
        );

        yield put(EditMeetingSuccess.action({ updatedMeeting: payload }));

        if (data.updateMeetingResult === UpdateMeetingResult.Success) {
          meta?.deferred.resolve();
        } else {
          meta?.deferred.reject(data.updateMeetingResult);
        }
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IUpdateMeetingResponse>, IUpdateMeetingRequest>(
      MAIN_API.EDIT_MEETING,
      HttpRequestMethod.Put,
    );
  }
}
