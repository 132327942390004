import { createAction } from '@reduxjs/toolkit';
import {
  DEFAULT_MEETING_DURATION,
  DEFAULT_PARTICIPANTS_COUNT,
  DEFAULT_VIDEO_PREFERENCE,
  FULL_DATE,
} from 'common/constants';
import dayjs from 'dayjs';
import { IMeeting, MeetingType } from 'lingopractices-models';
import { ICoords } from 'screens/types';

import { IMeetingsState, TCreateMeetingData, TopicEnum } from '../../types';

export class SetSelectedMeeting {
  static get action() {
    return createAction<IMeeting | null>('meetings/SET_EDITABLE_MEETING');
  }

  static get reducer() {
    return (
      draft: IMeetingsState,
      { payload: meeting }: ReturnType<typeof SetSelectedMeeting.action>,
    ) => {
      if (meeting) {
        const {
          language,
          languageLevel,
          maxParticipantsCount,
          topicName,
          topicDescription,
          comment,
          meetingDate,
          type,
          address,
          preferVideo,
          duration,
          customMeetingInfo,
        } = meeting;

        const meetingDateString = dayjs(meetingDate).format(FULL_DATE);

        const online = type !== MeetingType.Offline && type !== MeetingType.None;

        draft.createMeetingData = {
          language,
          meetingType: type,
          participantsCount: maxParticipantsCount,
          topicType: TopicEnum.CUSTOM,
          level: languageLevel,
          customTopic: topicName,
          customTopicDescription: topicDescription || '',
          note: comment || '',
          date: meetingDateString,
          time: meetingDateString,
          location: address?.additionalInfo,
          shortLocation: address?.address,
          latLng: !online
            ? { lat: address?.latitude as number, lng: address?.longitude as number }
            : ({} as ICoords),
          preferVideo: preferVideo || DEFAULT_VIDEO_PREFERENCE,
          duration: duration || DEFAULT_MEETING_DURATION,
          customMeetingInfo,
        };

        draft.myMeetings.selectedMeeting = meeting;
      } else {
        draft.createMeetingData = {
          topicType: TopicEnum.OUR,
          meetingType: MeetingType.GoogleMeet,
          participantsCount: DEFAULT_PARTICIPANTS_COUNT,
          preferVideo: DEFAULT_VIDEO_PREFERENCE,
          duration: DEFAULT_MEETING_DURATION,
        } as TCreateMeetingData;
        draft.myMeetings.selectedMeeting = null;
      }

      return draft;
    };
  }
}
