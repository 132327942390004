import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { IMeetingPackage } from 'lingopractices-models/src/main';
import { orderBy } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetMeetingPackagesSuccess } from './get-meeting-packages-success';

export class GetMeetingPackages {
  static get action() {
    return createDeferredAction('purchase/GET_MEETING_PACKAGES');
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetMeetingPackages.action>): SagaIterator {
      try {
        const { data } = GetMeetingPackages.httpRequest.call(
          yield call(() => GetMeetingPackages.httpRequest.generator()),
        );

        const sortedData = orderBy(data, ['amount'], ['desc']);

        yield put(GetMeetingPackagesSuccess.action(sortedData));
        meta?.deferred.resolve(sortedData);
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IMeetingPackage[]>, null>(
      MAIN_API.GET_MEETING_PACKAGES,
      HttpRequestMethod.Get,
    );
  }
}
