import { MarkBannerWatchedSuccess } from '@store/banners/features/mark-banner-watched/mark-banner-watched-success';
import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export interface IMarkBannerWatchedPayload {
  bannerId: number;
}

export class MarkBannerWatched {
  static get action() {
    return createDeferredAction<IMarkBannerWatchedPayload>('banners/MAKE_BANNER_WATCHED');
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof MarkBannerWatched.action>): SagaIterator {
      try {
        MarkBannerWatched.httpRequest.call(
          yield call(() => MarkBannerWatched.httpRequest.generator(payload)),
        );

        yield put(MarkBannerWatchedSuccess.action(payload.bannerId));
        meta?.deferred.resolve();
      } catch (e) {
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, IMarkBannerWatchedPayload>(
      ({ bannerId }) => replaceInUrl(MAIN_API.MARK_BANNER_WATCHED, ['bannerId', bannerId]),
      HttpRequestMethod.Put,
    );
  }
}
