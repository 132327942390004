import { createAction } from '@reduxjs/toolkit';

import { IMeetingsState } from '../../types';

interface IEditMeetingNotificationSuccessPayload {
  meetingId: number;
  notificationEnabled: boolean;
}

export class EditMeetingNotificationSuccess {
  static get action() {
    return createAction<IEditMeetingNotificationSuccessPayload>(
      'meetings/EDIT_NOTIFICATION_SUCCESS',
    );
  }

  static get reducer() {
    return (
      draft: IMeetingsState,
      { payload }: ReturnType<typeof EditMeetingNotificationSuccess.action>,
    ) => {
      const { meetingId, notificationEnabled } = payload;

      draft.requests.editMeetingNotificationPending = false;

      if (draft.myMeetings.selectedMeeting) {
        draft.myMeetings.selectedMeeting.notificationsEnabled = notificationEnabled;
      }

      return draft;
    };
  }
}
