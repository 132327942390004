import { createAction } from '@reduxjs/toolkit';
import { IAlertState } from '@store/alerts/types';
import { INotificationPreference } from 'lingopractices-models';

export class CreateAlertSuccess {
  static get action() {
    return createAction<INotificationPreference>('notifications/CREATE_ALERT_SUCCESS');
  }

  static get reducer() {
    return (draft: IAlertState, { payload }: ReturnType<typeof CreateAlertSuccess.action>) => {
      draft.requests.createNotificationsPending = false;

      if (!draft.notificationsPreferecnces) {
        draft.notificationsPreferecnces = [payload];
      } else {
        draft.notificationsPreferecnces = [...draft.notificationsPreferecnces, payload];
      }
    };
  }
}
