import { createReducer } from '@reduxjs/toolkit';
import { DeleteMeetingFromStore } from '@store/meetings/features/delete-meeting-from-store/delete-meeting-from-store';
import { GetPastMeetingsSuccess } from '@store/meetings/features/get-meetings/get-past-meetings-success';
import {
  DEFAULT_MEETING_DURATION,
  DEFAULT_PARTICIPANTS_COUNT,
  DEFAULT_VIDEO_PREFERENCE,
} from 'common/constants';
import { MeetingType } from 'lingopractices-models';
import { MeetingsFiltersService } from 'services/meetings-filters-service';

import { CreateMeeting } from './features/create-meeting/create-meeting';
import { CreateMeetingFailure } from './features/create-meeting/create-meeting-failure';
import { CreateMeetingSuccess } from './features/create-meeting/create-meeting-success';
import { EditMeeting } from './features/edit-meeting/edit-meeting';
import { EditMeetingSuccess } from './features/edit-meeting/edit-meeting-success';
import { EditMeetingNotification } from './features/edit-notification/edit-notification';
import { EditMeetingNotificationFailure } from './features/edit-notification/edit-notification-failure';
import { EditMeetingNotificationSuccess } from './features/edit-notification/edit-notification-success';
import { SwitchFilters } from './features/filters/switch-filters';
import { UpdateFilters } from './features/filters/update-filters';
import { GetMeetings } from './features/get-meetings/get-meetings';
import { GetMeetingsFailure } from './features/get-meetings/get-meetings-failure';
import { GetMeetingsSuccess } from './features/get-meetings/get-meetings-success';
import { GetMyMeetingsFailure } from './features/get-my-meetings/get-my-meeting-failure';
import { GetMyMeetings } from './features/get-my-meetings/get-my-meetings';
import { GetMyMeetingsSuccess } from './features/get-my-meetings/get-my-meetings-success';
import { GetMyPastMeetingsSuccess } from './features/get-my-meetings/get-my-past-meetings-success';
import { JoinMeeting } from './features/join-meeting/join-meeting';
import { JoinMeetingFailure } from './features/join-meeting/join-meeting-failure';
import { JoinMeetingSuccess } from './features/join-meeting/join-meeting-success';
import { LeaveMeeting } from './features/leave-meeting/leave-meeting';
import { LeaveMeetingFailure } from './features/leave-meeting/leave-meeting-failure';
import { LeaveMeetingSuccess } from './features/leave-meeting/leave-meeting-success';
import { SetSelectedMeeting } from './features/set-selected-meeting/set-selected-meeting';
import { UpdateCreateMeetingData } from './features/update-create-meeting-data/update-create-meeting-data';
import { IMeetingFilter, IMeetingsState, TCreateMeetingData, TopicEnum } from './types';

const meetingFiltersService = new MeetingsFiltersService();

const { filters: localStorageFilters } = meetingFiltersService;

const initialState: IMeetingsState = {
  meetings: {
    meetingList: [],
    meetingListPast: [],
    hasMore: false,
    hasMorePast: false,
  },
  myMeetings: {
    meetingList: [],
    meetingListPast: [],
    hasMore: false,
    hasMorePast: false,
    selectedMeeting: null,
  },
  createMeetingData: {
    topicType: TopicEnum.OUR,
    meetingType: MeetingType.GoogleMeet,
    participantsCount: DEFAULT_PARTICIPANTS_COUNT,
    preferVideo: DEFAULT_VIDEO_PREFERENCE,
    duration: DEFAULT_MEETING_DURATION,
  } as TCreateMeetingData,
  filters: {
    allMeetingsFilter: localStorageFilters?.allMeetingsFilters || ({} as IMeetingFilter),
    myMeetingsFilter: localStorageFilters?.myMeetingsFilters || ({} as IMeetingFilter),
    isMyMeetingsOpened: false,
  },
  requests: {
    getMeetingsPending: false,
    getMyMeetingsPending: false,
    createMeetingPending: false,
    joinMeetingPending: false,
    leaveMeetingPending: false,
    editMeetingNotificationPending: false,
    editMeetingPending: false,
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(GetMeetings.action, GetMeetings.reducer)
    .addCase(GetMeetingsSuccess.action, GetMeetingsSuccess.reducer)
    .addCase(GetMeetingsFailure.action, GetMeetingsFailure.reducer)
    .addCase(GetMyMeetings.action, GetMyMeetings.reducer)
    .addCase(GetMyMeetingsFailure.action, GetMyMeetingsFailure.reducer)
    .addCase(GetMyMeetingsSuccess.action, GetMyMeetingsSuccess.reducer)
    .addCase(JoinMeeting.action, JoinMeeting.reducer)
    .addCase(JoinMeetingSuccess.action, JoinMeetingSuccess.reducer)
    .addCase(LeaveMeeting.action, LeaveMeeting.reducer)
    .addCase(LeaveMeetingSuccess.action, LeaveMeetingSuccess.reducer)
    .addCase(LeaveMeetingFailure.action, LeaveMeetingFailure.reducer)
    .addCase(CreateMeeting.action, CreateMeeting.reducer)
    .addCase(CreateMeetingSuccess.action, CreateMeetingSuccess.reducer)
    .addCase(CreateMeetingFailure.action, CreateMeetingFailure.reducer)
    .addCase(JoinMeetingFailure.action, JoinMeetingFailure.reducer)
    .addCase(UpdateCreateMeetingData.action, UpdateCreateMeetingData.reducer)
    .addCase(UpdateFilters.action, UpdateFilters.reducer)
    .addCase(SwitchFilters.action, SwitchFilters.reducer)
    .addCase(EditMeetingNotification.action, EditMeetingNotification.reducer)
    .addCase(EditMeetingNotificationSuccess.action, EditMeetingNotificationSuccess.reducer)
    .addCase(EditMeetingNotificationFailure.action, EditMeetingNotificationFailure.reducer)
    .addCase(GetMyPastMeetingsSuccess.action, GetMyPastMeetingsSuccess.reducer)
    .addCase(GetPastMeetingsSuccess.action, GetPastMeetingsSuccess.reducer)
    .addCase(SetSelectedMeeting.action, SetSelectedMeeting.reducer)
    .addCase(EditMeeting.action, EditMeeting.reducer)
    .addCase(EditMeetingSuccess.action, EditMeetingSuccess.reducer)
    .addCase(DeleteMeetingFromStore.action, DeleteMeetingFromStore.reducer),
);

export default reducer;
