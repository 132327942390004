import { RefreshTopicGeneratorUses } from '@store/openAI/features/topic-generator-uses/refresh-topic-generator-uses';
import { all, takeLatest, takeLeading } from 'redux-saga/effects';

import { GetTopicQuestions } from './features/get-topic-questions/get-topic-questions';

export function* openAiSagas() {
  yield all([
    takeLatest(GetTopicQuestions.action, GetTopicQuestions.saga),
    takeLeading(RefreshTopicGeneratorUses.action, RefreshTopicGeneratorUses.saga),
  ]);
}
