import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetTagsFailure } from '@store/topics/features/get-tags/get-tags-failure';
import { IGetTagsPayload, ITopicsState } from '@store/topics/types';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ITag } from 'lingopractices-models/src/main';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetTagsSuccess } from './get-tags-success';

export class GetTags {
  static get action() {
    return createDeferredAction<IGetTagsPayload>('topics/GET_TAGS');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.getTagsPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof GetTags.action>): SagaIterator {
      try {
        const { data } = GetTags.httpRequest.call(
          yield call(() => GetTags.httpRequest.generator(payload)),
        );

        yield put(GetTagsSuccess.action(data));
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(GetTagsFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ITag[]>, IGetTagsPayload>(
      MAIN_API.SEARCH_TAGS,
      HttpRequestMethod.Post,
    );
  }
}
