import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import {
  ICreateYookassaPaymentRequest,
  ICreateYookassaPaymentResponse,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { IPurchaseState } from '../types';

import { FinishPurchaseYookassa } from './finish-purchase-yookassa';
import { GetPaymentLinkYookassaSuccess } from './get-payment-link-yookassa-success';

export class GetPaymentLinkYookassa {
  static get action() {
    return createDeferredAction<ICreateYookassaPaymentRequest>(
      'purchase/GET_PAYMENT_LINK_YOOKASSA',
    );
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.getPaymentLinkPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof GetPaymentLinkYookassa.action>): SagaIterator {
      try {
        const { data } = GetPaymentLinkYookassa.httpRequest.call(
          yield call(() => GetPaymentLinkYookassa.httpRequest.generator(payload)),
        );

        if (data.link && data.paymentId) {
          yield put(GetPaymentLinkYookassaSuccess.action(data));
          yield put(FinishPurchaseYookassa.action(data));
          meta?.deferred.resolve(data);
        } else {
          meta?.deferred.reject();
        }
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<
      AxiosResponse<ICreateYookassaPaymentResponse>,
      ICreateYookassaPaymentRequest
    >(MAIN_API.GET_PAYMENT_LINK_YOOKASSA, HttpRequestMethod.Post);
  }
}
