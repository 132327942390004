import { RootState } from '@store/store';

export const getMyMeetingsSelector = (state: RootState) => state.meetings.myMeetings.meetingList;

export const getMyMeetingHasMoreSelector = (state: RootState) => state.meetings.myMeetings.hasMore;

export const getMyMeetingByIdSelector = (id: number) => (state: RootState) =>
  state.meetings.myMeetings.meetingList.find((meeting) => meeting.id === id) ||
  state.meetings.myMeetings.meetingListPast.find((meeting) => meeting.id === id);

export const getMyPastMeetingByIdSelector = (id: number) => (state: RootState) =>
  state.meetings.myMeetings.meetingListPast.find((meeting) => meeting.id === id);

export const getMeetingsSelector = (state: RootState) => state.meetings.meetings.meetingList;

export const getMeetingHasMoreSelector = (state: RootState) => state.meetings.meetings.hasMore;

export const getPastMeetingsSelector = (state: RootState) =>
  state.meetings.meetings.meetingListPast;

export const getPastMeetingHasMoreSelector = (state: RootState) =>
  state.meetings.meetings.hasMorePast;

export const getMeetingByIdSelector = (id: number) => (state: RootState) =>
  state.meetings.meetings.meetingList.find((meeting) => meeting.id === id);

export const getLeaveMeetingPendingSelector = (state: RootState) =>
  state.meetings.requests.leaveMeetingPending;

export const getMeetingsPendingSelector = (state: RootState) =>
  state.meetings.requests.getMeetingsPending;

export const getMeetingJoinPendingSelector = (state: RootState) =>
  state.meetings.requests.joinMeetingPending;

export const getCreateMeetingPendingSelector = (state: RootState) =>
  state.meetings.requests.createMeetingPending;

export const myMeetingsPendingSelector = (state: RootState) =>
  state.meetings.requests.getMyMeetingsPending;

export const createMeetingDataSelector = (state: RootState) => state.meetings.createMeetingData;

export const allFiltersSelector = (state: RootState) => state.meetings.filters;

export const openedMyMeetingsSelector = (state: RootState) =>
  state.meetings.filters.isMyMeetingsOpened;

export const currentFiltersSelector = (state: RootState) =>
  state.meetings.filters.isMyMeetingsOpened
    ? state.meetings.filters.myMeetingsFilter
    : state.meetings.filters.allMeetingsFilter;

export const editMeetingNotificationsPending = (state: RootState) =>
  state.meetings.requests.editMeetingNotificationPending;

export const getMyPastMeetingsSelector = (state: RootState) =>
  state.meetings.myMeetings.meetingListPast;

export const getMyPastMeetingHasMoreSelector = (state: RootState) =>
  state.meetings.myMeetings.hasMorePast;

export const selectedMeetingSelector = (state: RootState) =>
  state.meetings.myMeetings.selectedMeeting;

export const editMeetingPendingSelector = (state: RootState) =>
  state.meetings.requests.editMeetingPending;
