import { createAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { put } from 'redux-saga/effects';
import { AlertService } from 'services/alert-service';

import { SaveAlertState } from './save-alert';

export class GetAlertState {
  static get action() {
    return createAction('steps/GET_ALERT_STATE');
  }

  static get saga() {
    return function* (): SagaIterator {
      const alertService = new AlertService();
      const { alertState } = alertService;

      if (alertState) {
        yield put(SaveAlertState.action(alertState));
      }
    };
  }
}
