import { Theme } from '@store/profile/features/models/theme';

type ThemeType = {
  [key: string]: string;
};

function applyTheme(theme: Theme) {
  const lightTheme: ThemeType = {
    '--base-bg-color': '#FFFFFF',
    '--secondary-bg-color': 'rgba(63, 138, 224, 0.08)',
    '--secondary-bg-color-without-alpha': '#EBF3FB',
    '--base-color': '#000000',
    '--secondary-color': '#728A96',
    '--grey-bg': 'rgba(63, 138, 224, 0.08)',
    '--dt-blue-dark-wt-blue-light': '#0088CC',
    '--dt-blue-dark-wt-white': '#EFF7FB',
    '--dt-grey-opacity-wt-white-opaity': '#F3F8FC',
    '--change-theme': '#07A0EC',
    '--selected-item-bg': 'none',
    '--dt-white-wt-black': '#000000',
    '--dt-black-wt-white': '#FFFFFF',
    '--dt-grey-color-wt-grey-light-color': '#728A96',
    '--info-close-bg': '#969696',
    '--submit-button-bg': '#F9F9F9',
    '--dt-dark-grey-wt-light-grey': '#728A96',
    '--calendar-day': '#728A96',
    '--radio-line': '#DFDFDF',
    '--dt-white-wt-grey': '#728A96',
    '--dt-secondary-bg-wt-white': '#FFFFFF',
    '--select-next': 'rgba(63, 138, 224, 0.08)',
    '--radio-outer': '#969696',
    '--radio-outer-selcted': '#0088CC',
    '--light-red': '#E04A4A',
    '--dt-grey-wt-white': '#FFFFFF',
    '--dt-white-wt-light-blue': 'rgba(0, 136, 204, 0.08)',
    '--dt-white-wt-blue': '#0088CC',
    '--dt-light-black-wt-white': '#FFFFFF',
    '--dt-secondary-wt-white': '#FFFFFF',
    '--disabled-submit': '#3F8AE0',
    '--dt-blue-wt-gray': '#DFDFDF',
    '--off-switch': '#DFDFDF',
    '--dt-light-gray-wt-light-black': '#F4F4F4',
    '--drop-filter': 'drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1))',
    '--drop-dt-light-gray-wt-light': '#F0F6FD',
    '--dt-light-gray-wt-secondary-lt': 'rgba(63, 138, 224, 0.08)',
    '--dt-red-wt-red': '#D15050',
    '--overlay': 'rgb(49 48 52 / 50%)',
    '--overlay-blue': 'rgba(63, 138, 224, 0.3)',
    '--close-icon-gray': '#A5A5A5',
    '--home-tabs': 'rgba(63, 138, 224, 0.08)',
    '--home-tabs-selected': 'rgba(114, 138, 150, 0.2)',
    '--disabled-button': '#AAAAAA',
    '--border-gray': '#FFFFFF99',
    // new
    '--primary-content-0': '#000',
    '--primary-content-100': '#262214',
    '--primary-content-200': '#484331',
    '--primary-content-250': '#86D7FF',
    '--primary-content-300': '#8d8d8c',
    '--primary-content-400': '#606053',

    '--primary-accent-100': '#07A0EC',

    '--shades-bg-0': '#ececea',
    '--shades-bg-25': '#e7e7e5',
    '--shades-bg-50': '#e3e3e1',
    '--shades-bg-100': '#dededc',

    '--shades-highlight-100': '#d6d6d6',
    '--shades-highlight-200': '#bdb9b1',
    '--shades-highlight-300': '#caab98',

    '--shades-stroke': '#dcdcda',

    '--cod-grey': '#d6d1ce',
    '--filters-gradient': 'linear-gradient(#e3e3e3, #e3e3e300)',
    '--input-text': '#343434',
    '--disabled-button-color': '#c4c4c1',

    '--overlay-shades-bg-0': '#13131580',
    '--overlay-primary-4': '#ffffff0A',
    '--overlay-primary-12': '#ffffff1F',

    '--plus-screen-bg-item': '#FFFFFF0A',
    '--plus-screen-bg-selected-item': '#07A0EC1F',
    '--plus-screen-base-price': '#969696',

    '--service-alert': '#FF4848',
  };

  const darkTheme: ThemeType = {
    '--base-bg-color': '#313034',
    '--secondary-bg-color': 'rgba(255, 255, 255, 0.08)',
    '--secondary-bg-color-without-alpha': 'rgb(65,64,68)',
    '--base-color': '#FFFFFF',
    '--secondary-color': '#969696',
    '--grey-bg': 'rgba(118, 118, 128, 0.12)',
    '--dt-blue-dark-wt-blue-light': '#07A0EC',
    '--dt-blue-dark-wt-white': '#FFFFFF',
    '--dt-grey-opacity-wt-white-opaity': '#39393C',
    '--change-theme': '#07A0EC',
    '--selected-item-bg': 'transparent',
    '--dt-white-wt-black': '#FFFFFF',
    '--dt-grey-color-wt-grey-light-color': '#CBCBCB',
    '--info-close-bg': 'rgba(255, 255, 255, 0.08)',
    '--submit-button-bg': '#2B2B2B',
    '--dt-dark-grey-wt-light-grey': '#515151',
    '--calendar-day': '#728A96',
    '--radio-line': '#555555',
    '--dt-white-wt-grey': '#FFFFFF',
    '--dt-black-wt-white': '#000000',
    '--dt-secondary-bg-wt-white': 'rgba(255, 255, 255, 0.08)',
    '--select-next': '#414144',
    '--radio-outer': '#969696',
    '--radio-outer-selcted': '#0088CC',
    '--light-red': '#E04A4A',
    '--dt-grey-wt-white': '#555555',
    '--dt-white-wt-light-blue': '#FFFFFF',
    '--dt-white-wt-blue': '#FFFFFF',
    '--dt-light-black-wt-white': '#292D32',
    '--dt-secondary-wt-white': 'rgba(255, 255, 255, 0.08)',
    '--disabled-submit': '#3F8AE0',
    '--dt-blue-wt-gray': '#07A0EC',
    '--off-switch': '#DFDFDF',
    '--dt-light-gray-wt-light-black': '#2B2B2B',
    '--drop-filter': 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15))',
    '--drop-dt-light-gray-wt-light': '#444449',
    '--dt-light-gray-wt-secondary-lt': '#515151',
    '--dt-red-wt-red': '#D15050',
    '--overlay': 'rgb(49 48 52 / 50%)',
    '--overlay-blue': 'rgba(63, 138, 224, 0.3)',
    '--close-icon-gray': '#A5A5A5',
    '--home-tabs': '#3D3D3D',
    '--home-tabs-selected': '#5B5A5F',
    '--disabled-button': '#AAAAAA',
    '--border-gray': '#FFFFFF99',
    // new
    '--primary-content-0': '#ffffff',
    '--primary-content-100': '#D9DDEB',
    '--primary-content-200': '#B7BCCE',
    '--primary-content-250': '#86D7FF',
    '--primary-content-300': '#727273',
    '--primary-content-400': '#9F9FAC',

    '--primary-accent-100': '#07A0EC',

    '--shades-bg-0': '#131315',
    '--shades-bg-25': '#18181A',
    '--shades-bg-50': '#1C1C1E',
    '--shades-bg-100': '#212123',

    '--shades-highlight-100': '#292929',
    '--shades-highlight-200': '#42464E',
    '--shades-highlight-300': '#355467',

    '--shades-stroke': '#232325',

    '--cod-grey': '#292E31',
    '--filters-gradient': 'linear-gradient(#1C1C1C, #1C1C1C00)',
    '--input-text': '#CBCBCB',
    '--disabled-button-color': '#3B3B3E',

    '--overlay-shades-bg-0': '#13131580',
    '--overlay-primary-4': '#ffffff0A',
    '--overlay-primary-12': '#ffffff1F',

    '--plus-screen-bg-item': '#FFFFFF0A',
    '--plus-screen-bg-selected-item': '#07A0EC1F',
    '--plus-screen-base-price': '#969696',

    '--service-alert': '#FF4848',
  };

  const root = document.documentElement;

  if (theme === Theme.LIGHT) {
    Object.keys(lightTheme).forEach((color) => {
      root.style.setProperty(color, lightTheme[color]);
    });
  } else {
    Object.keys(darkTheme).forEach((color) => {
      root.style.setProperty(color, darkTheme[color]);
    });
  }
}

export default applyTheme;
