import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { DeleteMeetingFromStore } from '@store/meetings/features/delete-meeting-from-store/delete-meeting-from-store';
import { getMeetingByIdSelector, selectedMeetingSelector } from '@store/meetings/selectors';
import { IMeetingsState } from '@store/meetings/types';
import { getProfileDataSelector } from '@store/profile/selectors';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import {
  IJoinMeetingRequest,
  IJoinMeetingResponse,
  IMeeting,
  IParticipant,
  IUser,
  UpdateAttendeesResult,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { JoinMeetingFailure } from './join-meeting-failure';
import { JoinMeetingSuccess } from './join-meeting-success';

export class JoinMeeting {
  static get action() {
    return createDeferredAction<IJoinMeetingRequest>('meetings/JOIN_MEETING');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.joinMeetingPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof JoinMeeting.action>): SagaIterator {
      try {
        const response = JoinMeeting.httpRequest.call(
          yield call(() => JoinMeeting.httpRequest.generator(payload)),
        );

        if (!response) {
          return;
        }

        const { result } = response.data;

        if (result === UpdateAttendeesResult.Success) {
          const { id, gender, username, countryName, countryCode, aboutMe, registeredAt }: IUser =
            yield select(getProfileDataSelector);
          const meetingById: IMeeting = yield select(getMeetingByIdSelector(payload.meetingId));
          const selectedMeeting = yield select(selectedMeetingSelector);
          const meeting = meetingById || selectedMeeting;

          const me: IParticipant = {
            userId: id,
            gender,
            username,
            country: countryName as string,
            countryCode,
            createdAt: registeredAt,
            aboutMe,
            blocked: false,
          };

          const newMeeting: IMeeting = {
            ...meeting,
            participants: [...meeting.participants, me],
          };

          yield put(JoinMeetingSuccess.action(newMeeting));
        } else {
          if (result === UpdateAttendeesResult.Canceled) {
            yield put(DeleteMeetingFromStore.action(payload.meetingId));
          }

          yield put(JoinMeetingFailure.action());
        }

        meta?.deferred.resolve({ result });
      } catch (e: any) {
        yield put(JoinMeetingFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IJoinMeetingResponse>, IJoinMeetingRequest>(
      MAIN_API.JOIN_MEETING,
      HttpRequestMethod.Post,
    );
  }
}
