import { createAction } from '@reduxjs/toolkit';
import { FileUploadState } from '@store/files/types';
import { produce } from 'immer';

export class UploadFileSuccess {
  static get action() {
    return createAction<string>('files/UPLOAD_FILE_SUCCESS');
  }

  static get reducer() {
    return produce(
      (
        draft: FileUploadState,
        { payload: uploadId }: ReturnType<typeof UploadFileSuccess.action>,
      ) => {
        delete draft[uploadId];
        return draft;
      },
    );
  }
}
