import { createAction } from '@reduxjs/toolkit';
import { IAlertState } from '@store/alerts/types';
import { CreateAlertType } from 'screens/types';

export class SaveAlertStateSuccess {
  static get action() {
    return createAction<CreateAlertType | undefined>('steps/SAVE_ALERT_STATE_SUCCESS');
  }

  static get reducer() {
    return (draft: IAlertState, { payload }: ReturnType<typeof SaveAlertStateSuccess.action>) => {
      draft.editAlert = payload;
    };
  }
}
