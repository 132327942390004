import { createReducer } from '@reduxjs/toolkit';
import { GetMeetingPackagesSuccess } from '@store/purchase/get-meeting-packages/get-meeting-packages-success';
import { GetPaymentLinkTelegram } from '@store/purchase/get-payment-link-telegram/get-payment-link-telegram';
import { GetPaymentLinkTelegramFailure } from '@store/purchase/get-payment-link-telegram/get-payment-link-telegram-failure';
import { GetPaymentLinkTelegramSuccess } from '@store/purchase/get-payment-link-telegram/get-payment-link-telegram-success';

import { FetchAvailablePurchases } from './fetch-available-purchases/fetch-available-purchases';
import { FetchAvailablePurchasesFailure } from './fetch-available-purchases/fetch-available-purchases-failure';
import { FetchAvailablePurchasesSuccess } from './fetch-available-purchases/fetch-available-purchases-success';
import { FinishPurchaseYookassa } from './payment-link-yookassa/finish-purchase-yookassa';
import { GetPaymentLinkYookassa } from './payment-link-yookassa/get-payment-link-yookassa';
import { GetPaymentLinkYookassaFailure } from './payment-link-yookassa/get-payment-link-yookassa-failure';
import { GetPaymentLinkYookassaSuccess } from './payment-link-yookassa/get-payment-link-yookassa-success';
import { FinishPurchase } from './start-finish-purchase/finish-purchase';
import { FinishPurchaseNoFetchProfile } from './start-finish-purchase/finish-purchase-no-fetch-profile';
import { ResetPurchaseState } from './start-finish-purchase/reset-purchase-state';
import { StartPurchase } from './start-finish-purchase/start-purchase';
import { IPurchaseState } from './types';

export const purchaseState: IPurchaseState = {
  requests: {
    purchasePending: false,
    purchaseStartTimeInMs: 0,
    postPurchaseProfileUpdatePending: false,
    fetchAvailablePurchasesPending: false,
    getPaymentLinkPending: false,
  },
  paymentLinks: {
    yookassa: undefined,
  },
  subscriptions: [],
  meetingPackages: [],
};

const reducer = createReducer<IPurchaseState>(purchaseState, (builder) =>
  builder
    .addCase(StartPurchase.action, StartPurchase.reducer)
    .addCase(FinishPurchaseNoFetchProfile.action, FinishPurchaseNoFetchProfile.reducer)
    .addCase(FinishPurchase.action, FinishPurchase.reducer)
    .addCase(ResetPurchaseState.action, ResetPurchaseState.reducer)
    .addCase(GetPaymentLinkYookassa.action, GetPaymentLinkYookassa.reducer)
    .addCase(GetPaymentLinkYookassaSuccess.action, GetPaymentLinkYookassaSuccess.reducer)
    .addCase(GetPaymentLinkYookassaFailure.action, GetPaymentLinkYookassaFailure.reducer)
    .addCase(FinishPurchaseYookassa.action, FinishPurchaseYookassa.reducer)
    .addCase(FetchAvailablePurchases.action, FetchAvailablePurchases.reducer)
    .addCase(FetchAvailablePurchasesSuccess.action, FetchAvailablePurchasesSuccess.reducer)
    .addCase(FetchAvailablePurchasesFailure.action, FetchAvailablePurchasesFailure.reducer)
    .addCase(GetPaymentLinkTelegram.action, GetPaymentLinkTelegram.reducer)
    .addCase(GetPaymentLinkTelegramSuccess.action, GetPaymentLinkTelegramSuccess.reducer)
    .addCase(GetPaymentLinkTelegramFailure.action, GetPaymentLinkTelegramFailure.reducer)
    .addCase(GetMeetingPackagesSuccess.action, GetMeetingPackagesSuccess.reducer),
);

export default reducer;
