import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { logError } from 'core/sentry';
import { ICreateFeedbackRequest } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export class SendFeedback {
  static get action() {
    return createDeferredAction<ICreateFeedbackRequest>('chat/SEND_FEEDBACK');
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof SendFeedback.action>): SagaIterator {
      const { text } = payload;

      try {
        const response = SendFeedback.httpRequest.call(
          yield call(() => SendFeedback.httpRequest.generator({ text })),
        );

        if (response.status === HttpStatusCode.Ok) {
          meta?.deferred.resolve();
        } else {
          meta?.deferred.reject();
        }
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<string>, ICreateFeedbackRequest>(
      MAIN_API.SEND_FEEDBACK,
      HttpRequestMethod.Post,
    );
  }
}
