import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetStatisticsByIdFailure } from '@store/users/features/get-statistics-by-id/get-statistics-by-id-failure';
import { GetStatisticsByIdSuccess } from '@store/users/features/get-statistics-by-id/get-statistics-by-id-success';
import { IUserIdPayload, IUsersState } from '@store/users/types';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { IStatistic } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export class GetStatisticsById {
  static get action() {
    return createDeferredAction<IUserIdPayload>('users/GET_STATISTICS_BY_ID');
  }

  static get reducer() {
    return (draft: IUsersState) => {
      draft.requests.getStatisticsByUserIdPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof GetStatisticsById.action>): SagaIterator {
      try {
        const { data } = GetStatisticsById.httpRequest.call(
          yield call(() => GetStatisticsById.httpRequest.generator(payload.id)),
        );

        yield put(GetStatisticsByIdSuccess.action(data));
        meta?.deferred.resolve();
      } catch (e) {
        yield put(GetStatisticsByIdFailure.action());
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IStatistic>, number>(
      (id) => replaceInUrl(MAIN_API.GET_STATISTICS_BY_ID, ['id', id]),
      HttpRequestMethod.Get,
    );
  }
}
