import { getChatByIdDraftSelector } from '@store/chats/selectors';
import { IChat, IChatsState } from '@store/chats/types';
import { createDeferredAction } from '@store/common/actions';
import { MESSAGES_LIMITS } from '@utils/pagination-limits';
import { logError } from 'core/sentry';
import { IPaginationParams } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, cancel, cancelled, delay, fork, put, take } from 'redux-saga/effects';

import { GetChat } from '../get-chat/get-chat';
import { GetChatSuccess } from '../get-chat/get-chat-success';

import { CancelCheckMessages } from './cancel-check-messages';

export interface ICheckNewMessagesPayload {
  chatId: number;
}

export class CheckNewMessages {
  static get action() {
    return createDeferredAction<ICheckNewMessagesPayload>('chat/CHECK_NEW_MESSAGES');
  }

  static get reducer() {
    return (draft: IChatsState, { payload }: ReturnType<typeof CheckNewMessages.action>) => {
      draft.requests.getChatMessagesPending = true;

      const { chatId } = payload;

      const chat = getChatByIdDraftSelector(chatId, draft);

      if (!chat) {
        const newChat: IChat = {
          messages: [],
          hasMore: true,
        };

        draft.chats = {
          ...draft.chats,
          [payload.chatId]: newChat,
        };
      }
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof CheckNewMessages.action>): SagaIterator {
      const { chatId } = payload;

      function* getChat(): SagaIterator {
        while (true) {
          if (yield cancelled()) {
            break;
          }

          const page: IPaginationParams = {
            offset: 0,
            limit: MESSAGES_LIMITS,
          };

          try {
            const { data } = GetChat.httpRequest.call(
              yield call(() =>
                GetChat.httpRequest.generator({
                  paginationParams: page,
                  meetingId: chatId,
                }),
              ),
            );

            const hasMore = data.length >= page.limit;

            yield put(GetChatSuccess.action({ chatId, messages: data, hasMore }));
            meta?.deferred.resolve();
          } catch (e: any) {
            meta?.deferred.reject(e);
            logError(e);
          }

          yield delay(4000);
        }
      }

      const getChatTask = yield fork(getChat);

      yield take(CancelCheckMessages.action);

      yield cancel(getChatTask);
    };
  }
}
