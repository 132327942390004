import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';
import { ITag } from 'lingopractices-models/src/main';

export class GetTagsSuccess {
  static get action() {
    return createAction<ITag[]>('topics/GET_TAGS_SUCCESS');
  }

  static get reducer() {
    return (draft: ITopicsState, { payload }: ReturnType<typeof GetTagsSuccess.action>) => {
      draft.requests.getTagsPending = false;
      draft.tags = payload || [];

      return draft;
    };
  }
}
