import { startParamFromURLSelector } from '@store/auth/selectors';
import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

export function* getStartParamHeader(): SagaIterator {
  const startParam: string = yield select(startParamFromURLSelector);

  return {
    'X-Webapp-Start-Parameter': startParam,
  };
}
