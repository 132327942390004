import { createAction } from '@reduxjs/toolkit';
import { apply } from 'redux-saga/effects';

import { CountShowingAdultBannerService } from '../../../../services/count-showing-adult-banner-service';

export class CountShowingAdultBanner {
  static get action() {
    return createAction('init/COUNT_SHOWING_ADULT_BANNER');
  }

  static get saga() {
    return function* () {
      const countService = new CountShowingAdultBannerService();

      if (countService.count) {
        if (countService.count < 2) {
          yield apply(countService, countService.initializeOrUpdate, [countService.count + 1]);
        }
      } else {
        yield apply(countService, countService.initializeOrUpdate, [1]);
      }
    };
  }
}
