import { RootState } from '@store/store';

import { IChatsState } from './types';

export const getChatByIdSelector = (id: number) => (state: RootState) => state.chats.chats[id];
export const getChatByIdDraftSelector = (id: number, draft: IChatsState) => draft.chats[id];
export const getMessagesByChatId = (id?: number) => (state: RootState) =>
  state.chats.chats[id || -1]?.messages;

export const getGetChatPendingSelector = (state: RootState) =>
  state.chats.requests.getChatMessagesPending;

export const getHasMoreByChatIdSelector = (id?: number) => (state: RootState) =>
  state.chats.chats[id || -1]?.hasMore;

export const getSendMessagePendingSelector = (state: RootState) =>
  state.chats.requests.createMessagePending;
