import { getInterfaceLanguageSelector } from '@store/profile/selectors';
import { ILanguage } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { select } from 'redux-saga/effects';

export function* getLocaleHeader(): SagaIterator {
  const interfaceLanguage: ILanguage | undefined = yield select(getInterfaceLanguageSelector);

  return {
    'Accept-Language': `${interfaceLanguage?.id}`,
  };
}
