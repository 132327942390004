import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetNotificationScheduleFailure } from '@store/notifications/features/notification-schedule/get-notification-schedule/get-notification-schedule-failure';
import { INotificationsState } from '@store/notifications/types';
import { AxiosResponse } from 'axios';
import { IPushNotificationSchedulePreference } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetNotificationScheduleSuccess } from './get-notification-schedule-success';

export class GetNotificationSchedule {
  static get action() {
    return createDeferredAction('notifications/GET_NOTIFICATION_SCHEDULE');
  }

  static get reducer() {
    return (draft: INotificationsState) => {
      draft.requests.getNotificationSchedulePending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetNotificationSchedule.action>): SagaIterator {
      try {
        const { data } = GetNotificationSchedule.httpRequest.call(
          yield call(() => GetNotificationSchedule.httpRequest.generator()),
        );

        yield put(GetNotificationScheduleSuccess.action(data));
        meta?.deferred.resolve(data);
      } catch (e) {
        yield put(GetNotificationScheduleFailure.action());
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IPushNotificationSchedulePreference>, undefined>(
      MAIN_API.NOTIFICATIONS_SCHEDULE,
      HttpRequestMethod.Get,
    );
  }
}
