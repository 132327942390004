import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetProfile } from '@store/profile/features/get-profile/get-profile';
import { GetProfileSuccess } from '@store/profile/features/get-profile/get-profile-success';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { SagaIterator } from 'redux-saga';
import { call, put, take } from 'redux-saga/effects';

export class DisableAutoWithdrawalYookassa {
  static get action() {
    return createDeferredAction('purchase/DISABLE_AUTO_WITHDRAWAL_YOOKASSA');
  }

  static get saga() {
    return function* ({
      meta,
    }: ReturnType<typeof DisableAutoWithdrawalYookassa.action>): SagaIterator {
      try {
        const { data } = DisableAutoWithdrawalYookassa.httpRequest.call(
          yield call(() => DisableAutoWithdrawalYookassa.httpRequest.generator()),
        );
        yield put(GetProfile.action({}));
        yield take(GetProfileSuccess.action);
        meta?.deferred.resolve(data);
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, undefined>(
      MAIN_API.DISABLE_AUTO_WITHDRAWAL_YOOKASSA,
      HttpRequestMethod.Post,
    );
  }
}
