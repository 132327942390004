import { createAction } from '@reduxjs/toolkit';
import { INotificationsState } from '@store/notifications/types';

export class GetNotificationScheduleFailure {
  static get action() {
    return createAction('notifications/GET_NOTIFICATION_SCHEDULE_FAILURE');
  }

  static get reducer() {
    return (draft: INotificationsState) => {
      draft.requests.getNotificationSchedulePending = false;
      return draft;
    };
  }
}
