import React from 'react';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

export const initSentry = () => {
  if (!import.meta.env.VITE_LOCAL) {
    Sentry.init({
      dsn: import.meta.env.VITE_SENTRY_DSN,
      integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/dev\.api\.lingopraxis\.com\/api/,
        /^https:\/\/api\.lingopraxis\.com\/api/,
        /^https:\/\/api-prod-lkxvr\.ondigitalocean\.app\/api/,
      ],
      environment: import.meta.env.VITE_ENVIRONMENT,
      sendDefaultPii: true,
      normalizeDepth: 5,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
