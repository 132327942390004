import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';

interface IEditTopicPayload {
  id: number;
  newQuestions: string[];
}

export class EditTopicSuccess {
  static get action() {
    return createAction<IEditTopicPayload>('topics/EDIT_TOPIC_SUCCESS');
  }

  static get reducer() {
    return (draft: ITopicsState, { payload }: ReturnType<typeof EditTopicSuccess.action>) => {
      const { id, newQuestions } = payload;

      draft.requests.editTopicPending = false;

      const editedTopic = draft.topics.find((topic) => topic.id === id);
      const editedTopicByTagId = draft.topicsByTagId.find((topic) => topic.id === id);

      if (editedTopic?.questions) {
        editedTopic.questions = [...editedTopic.questions, ...newQuestions];
      }

      if (editedTopicByTagId?.questions) {
        editedTopicByTagId.questions = [...editedTopicByTagId.questions, ...newQuestions];
      }
    };
  }
}
