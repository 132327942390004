import { createReducer } from '@reduxjs/toolkit';
import { GetStatisticsById } from '@store/users/features/get-statistics-by-id/get-statistics-by-id';
import { GetStatisticsByIdFailure } from '@store/users/features/get-statistics-by-id/get-statistics-by-id-failure';
import { GetStatisticsByIdSuccess } from '@store/users/features/get-statistics-by-id/get-statistics-by-id-success';

import { IUsersState } from './types';

export const usersState: IUsersState = {
  statisticsByUserId: undefined,
  requests: {
    getStatisticsByUserIdPending: false,
  },
};

const reducer = createReducer<IUsersState>(usersState, (builder) =>
  builder
    .addCase(GetStatisticsById.action, GetStatisticsById.reducer)
    .addCase(GetStatisticsByIdSuccess.action, GetStatisticsByIdSuccess.reducer)
    .addCase(GetStatisticsByIdFailure.action, GetStatisticsByIdFailure.reducer),
);

export default reducer;
