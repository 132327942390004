import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { IBlockUserPayload } from '@store/users/types';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export class BlockUser {
  static get action() {
    return createDeferredAction<number>('users/BLOCK_USER');
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof BlockUser.action>): SagaIterator {
      try {
        BlockUser.httpRequest.call(
          yield call(() => BlockUser.httpRequest.generator({ userId: payload })),
        );

        meta?.deferred.resolve();
      } catch (e) {
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, IBlockUserPayload>(
      ({ userId }) => replaceInUrl(MAIN_API.BLOCK_USER, ['userId', userId]),
      HttpRequestMethod.Post,
    );
  }
}
