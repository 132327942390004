import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';

export class GetTagsFailure {
  static get action() {
    return createAction('topics/GET_TAGS_FAILURE');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.getTagsPending = false;

      return draft;
    };
  }
}
