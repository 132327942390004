import { BlockUser } from '@store/users/features/block-user/block-user';
import { UnblockUser } from '@store/users/features/unblock-user/unblock-user';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { GetStatisticsById } from './features/get-statistics-by-id/get-statistics-by-id';

export function* usersSagas() {
  yield all([
    takeEvery(GetStatisticsById.action, GetStatisticsById.saga),
    takeLatest(BlockUser.action, BlockUser.saga),
    takeLatest(UnblockUser.action, UnblockUser.saga),
  ]);
}
