import { createAction } from '@reduxjs/toolkit';
import { IMeetingPackage } from 'lingopractices-models/src/main';

import { IPurchaseState } from '../types';

export class GetMeetingPackagesSuccess {
  static get action() {
    return createAction<IMeetingPackage[]>('purchase/GET_MEETING_PACKAGES_SUCCESS');
  }

  static get reducer() {
    return (
      draft: IPurchaseState,
      { payload }: ReturnType<typeof GetMeetingPackagesSuccess.action>,
    ) => {
      draft.meetingPackages = payload;

      return draft;
    };
  }
}
