import { MeetingPlace } from '@store/meetings/types';
import { MeetingType } from 'lingopractices-models';

export const getMeetingTypeByMeetingPlace = (place: MeetingPlace | null) => {
  if (!place) {
    return null;
  }

  return place === MeetingPlace.Offline
    ? MeetingType.Offline
    : MeetingType.GoogleMeet | MeetingType.Zoom;
};
