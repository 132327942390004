import { CloudStorage } from '@utils/cloud-storage-tg';
import dayjs from 'dayjs';

const TOPIC_GENERATOR_USES_LEFT = 'topic-generator-uses-left';
const TOPIC_GENERATOR_USES_REFRESHED_DATE = 'topic-generator-use-refreshed-date';

export class PlusFeaturesRepository {
  private static readonly prefix = 'plus';

  private static cloudStorage = new CloudStorage(this.prefix);

  public static setTGUsesLeft(value: number): void {
    this.cloudStorage.setItem(TOPIC_GENERATOR_USES_LEFT, value.toString());
  }

  public static async getTGUsesLeft(): Promise<number> {
    try {
      const response = await PlusFeaturesRepository.cloudStorage.getItem(TOPIC_GENERATOR_USES_LEFT);
      return Number(response);
    } catch {
      return 0;
    }
  }

  public static setLastTGUsesRefreshedTimestampToNow(): void {
    this.cloudStorage.setItem(TOPIC_GENERATOR_USES_REFRESHED_DATE, dayjs().valueOf().toString());
  }

  public static async getLastTGUsesRefreshedTimestamp(): Promise<number> {
    try {
      const response = await PlusFeaturesRepository.cloudStorage.getItem(
        TOPIC_GENERATOR_USES_REFRESHED_DATE,
      );
      return Number(response);
    } catch {
      return 0;
    }
  }
}
