import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import {
  GetPastMeetingsSuccess
} from '@store/meetings/features/get-meetings/get-past-meetings-success';
import { getMeetingsSelector, getPastMeetingsSelector } from '@store/meetings/selectors';
import { IMeetingsState, MeetingPlace } from '@store/meetings/types';
import { getMeetingTypeByMeetingPlace } from '@utils/meeting-utils';
import { MEETINGS_LIMITS } from '@utils/pagination-limits';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { Dayjs } from 'dayjs';
import {
  IGetMeetingsRequest,
  IMeeting,
  IPaginationParams,
  LanguageLevel,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { GetMeetingsFailure } from './get-meetings-failure';
import { GetMeetingsSuccess } from './get-meetings-success';

interface IGetMeetingsPayload {
  languageId?: string;
  languageLevel: LanguageLevel | null;
  from?: Dayjs | null;
  to?: Dayjs | null;
  place: MeetingPlace | null;
  initializedByScroll: boolean;
  onlyPast?: boolean | null;
}

export class GetMeetings {
  static get action() {
    return createDeferredAction<IGetMeetingsPayload>('meetings/GET_MEETINGS');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.getMeetingsPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof GetMeetings.action>): SagaIterator {
      const {
        to,
        from: dateFrom,
        initializedByScroll,
        languageLevel,
        languageId,
        place,
        onlyPast,
      } = payload;

      const meetingsList = onlyPast
        ? yield select(getPastMeetingsSelector)
        : yield select(getMeetingsSelector);

      const page: IPaginationParams = {
        offset: initializedByScroll ? meetingsList?.length : 0,
        limit: MEETINGS_LIMITS,
      };

      try {
        const { data } = GetMeetings.httpRequest.call(
          yield call(() =>
            GetMeetings.httpRequest.generator({
              languageLevel,
              languageId,
              type: getMeetingTypeByMeetingPlace(place),
              page,
              from: dateFrom?.toJSON() || null,
              to: to?.toJSON() || null,
              withFreePlacesOnly: false,
              onlyPast: onlyPast || false,
            }),
          ),
        );

        const hasMore = data.length >= page.limit;

        if (onlyPast) {
          yield put(GetPastMeetingsSuccess.action({ data, hasMore, initializedByScroll }));
        }

        if (!onlyPast) {
          yield put(GetMeetingsSuccess.action({ data, hasMore, initializedByScroll }));
          if (!hasMore)
            yield put(
              GetMeetings.action({
                initializedByScroll,
                from: dateFrom,
                to,
                languageId,
                languageLevel,
                place,
                onlyPast: true,
              }),
            );
        }
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(GetMeetingsFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IMeeting[]>, IGetMeetingsRequest>(
      MAIN_API.SEARCH_MEETINGS,
      HttpRequestMethod.Post,
    );
  }
}
