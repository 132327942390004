import { createAction } from '@reduxjs/toolkit';
import { IChatsState } from '@store/chats/types';

export class CreateMessageFailure {
  static get action() {
    return createAction('chat/CREATE_MESSAGE_FAILURE');
  }

  static get reducer() {
    return (draft: IChatsState) => {
      draft.requests.createMessagePending = false;
    };
  }
}
