import { createAction } from '@reduxjs/toolkit';
import { IBannersState } from '@store/banners/types';

export class MarkBannerWatchedSuccess {
  static get action() {
    return createAction<number>('banners/MAKE_BANNER_WATCHED_SUCCESS');
  }

  static get reducer() {
    return (
      draft: IBannersState,
      { payload }: ReturnType<typeof MarkBannerWatchedSuccess.action>,
    ) => {
      draft.banners = draft.banners.filter((item) => item.bannerId !== payload);

      return draft;
    };
  }
}
