import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetUserBotsSuccess } from '@store/user-bots/features/get-user-bots/get-user-bots-success';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ITelegramBotInfo } from 'lingopractices-models/src/main';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export class GetUserBots {
  static get action() {
    return createDeferredAction('user_bots/GET_USER_BOTS');
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetUserBots.action>): SagaIterator {
      try {
        const { data } = GetUserBots.httpRequest.call(
          yield call(() => GetUserBots.httpRequest.generator()),
        );

        yield put(GetUserBotsSuccess.action(data));
        meta?.deferred.resolve(data);
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ITelegramBotInfo[]>, undefined>(
      MAIN_API.USER_BOTS,
      HttpRequestMethod.Get,
    );
  }
}
