import { createAction } from '@reduxjs/toolkit';
import { FaqType, IProfileState } from '@store/profile/types';

export class GetFaqSuccess {
  static get action() {
    return createAction<Record<string, FaqType[]>>('profile/GET_FAQS_SUCCESS');
  }

  static get reducer() {
    return (draft: IProfileState, { payload }: ReturnType<typeof GetFaqSuccess.action>) => {
      draft.requests.getFaqsPending = false;

      draft.faqs = { ...draft.faqs, ...payload };
    };
  }
}
