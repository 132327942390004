import { createAction } from '@reduxjs/toolkit';
import { IBannersState } from '@store/banners/types';
import { IBannerLocalized } from 'lingopractices-models/src/main';

export class GetBannersSuccess {
  static get action() {
    return createAction<IBannerLocalized[]>('banners/GET_BANNERS_SUCCESS');
  }

  static get reducer() {
    return (draft: IBannersState, { payload }: ReturnType<typeof GetBannersSuccess.action>) => {
      draft.banners = payload || [];
      draft.requests.getBannersPending = false;

      return draft;
    };
  }
}
