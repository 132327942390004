import { createReducer } from '@reduxjs/toolkit';
import { GetTags } from '@store/topics/features/get-tags/get-tags';
import { GetTagsFailure } from '@store/topics/features/get-tags/get-tags-failure';
import { GetTagsSuccess } from '@store/topics/features/get-tags/get-tags-success';
import { GetTopicsByTagIdSuccess } from '@store/topics/features/get-topics-by-tagId/get-topics-by-tagId-success';
import { UpdateGeneratedTopicData } from '@store/topics/features/update-generated-topic-data/update-generated-topic-data';
import { IGenerateQuestionsRequest, LanguageLevel } from 'lingopractices-models';

import { INITIAL_AI_GENERATED_QUESTIONS_COUNT } from '../../screens/GenerateTopic/constants';

import { CreateTopic } from './features/create-topic/create-topic';
import { CreateTopicFailure } from './features/create-topic/create-topic-failure';
import { CreateTopicSuccess } from './features/create-topic/create-topic-success';
import { EditTopic } from './features/edit-topic/edit-topic';
import { EditTopicFailure } from './features/edit-topic/edit-topic-failure';
import { EditTopicSuccess } from './features/edit-topic/edit-topic-success';
import { GetTopicById } from './features/get-topic-by-id/get-topic-by-id';
import { GetTopicByIdFailure } from './features/get-topic-by-id/get-topic-by-id-failure';
import { GetTopicByIdSuccess } from './features/get-topic-by-id/get-topic-by-id-success';
import { GetTopics } from './features/get-topics/get-topics';
import { GetTopicsFailure } from './features/get-topics/get-topics-failure';
import { GetTopicsSuccess } from './features/get-topics/get-topics-success';
import { ITopicsState } from './types';

export const initialGeneratedTopicState: IGenerateQuestionsRequest = {
  topicName: '',
  languageId: '',
  languageLevel: LanguageLevel.None,
  questionsNumber: INITIAL_AI_GENERATED_QUESTIONS_COUNT,
  additionalPrompts: '',
};

const initialState: ITopicsState = {
  topics: [],
  hasMore: true,
  searchString: '',
  tags: [],
  topicsByTagId: [],
  requests: {
    getTopicsPending: false,
    createTopicPending: false,
    editTopicPending: false,
    getTopicByIdPending: false,
    getTagsPending: false,
  },
  aiGeneratedTopicData: initialGeneratedTopicState,
};

const reducer = createReducer<ITopicsState>(initialState, (builder) =>
  builder
    .addCase(GetTopics.action, GetTopics.reducer)
    .addCase(GetTopicsSuccess.action, GetTopicsSuccess.reducer)
    .addCase(GetTopicsFailure.action, GetTopicsFailure.reducer)
    .addCase(CreateTopicSuccess.action, CreateTopicSuccess.reducer)
    .addCase(CreateTopic.action, CreateTopic.reducer)
    .addCase(CreateTopicFailure.action, CreateTopicFailure.reducer)
    .addCase(EditTopicSuccess.action, EditTopicSuccess.reducer)
    .addCase(EditTopic.action, EditTopic.reducer)
    .addCase(EditTopicFailure.action, EditTopicFailure.reducer)
    .addCase(GetTopicById.action, GetTopicById.reducer)
    .addCase(GetTopicByIdSuccess.action, GetTopicByIdSuccess.reducer)
    .addCase(GetTopicByIdFailure.action, GetTopicByIdFailure.reducer)
    .addCase(GetTags.action, GetTags.reducer)
    .addCase(GetTagsSuccess.action, GetTagsSuccess.reducer)
    .addCase(GetTagsFailure.action, GetTagsFailure.reducer)
    .addCase(GetTopicsByTagIdSuccess.action, GetTopicsByTagIdSuccess.reducer)
    .addCase(UpdateGeneratedTopicData.action, UpdateGeneratedTopicData.reducer),
);

export default reducer;
