import { createAction } from '@reduxjs/toolkit';
import { IBannersState } from '@store/banners/types';

export class GetBannersFailure {
  static get action() {
    return createAction('banners/GET_BANNERS_FAILURE');
  }

  static get reducer() {
    return (draft: IBannersState) => {
      draft.requests.getBannersPending = false;
      return draft;
    };
  }
}
