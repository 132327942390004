import { createAction } from '@reduxjs/toolkit';
import { IProfileState } from '@store/profile/types';
import { IStatistic } from 'lingopractices-models';

export class GetMyStatisticsSuccess {
  static get action() {
    return createAction<IStatistic>('profile/GET_MY_STATISTICS_SUCCESS');
  }

  static get reducer() {
    return (
      draft: IProfileState,
      { payload }: ReturnType<typeof GetMyStatisticsSuccess.action>,
    ) => {
      draft.statistics = payload;
      draft.requests.getMyStatisticsPending = false;

      return draft;
    };
  }
}
