import { createAction } from '@reduxjs/toolkit';
import { IGetMeetingSuccessPayload, IMeetingsState } from '@store/meetings/types';
import { uniqBy } from 'lodash';

export class GetMyPastMeetingsSuccess {
  static get action() {
    return createAction<IGetMeetingSuccessPayload>('meetings/GET_MY_PAST_MEETINGS_SUCCESS');
  }

  static get reducer() {
    return (
      draft: IMeetingsState,
      { payload }: ReturnType<typeof GetMyPastMeetingsSuccess.action>,
    ) => {
      const { data, hasMore, initializedByScroll } = payload;

      draft.requests.getMyMeetingsPending = false;
      draft.myMeetings.hasMorePast = hasMore;
      draft.myMeetings.meetingListPast = uniqBy(
        initializedByScroll ? [...draft.myMeetings.meetingListPast, ...data] : data,
        'id',
      );

      return draft;
    };
  }
}
