import { createAction } from '@reduxjs/toolkit';
import { IMeetingsState, TCreateMeetingData, TopicEnum } from '@store/meetings/types';
import { DEFAULT_MEETING_DURATION, DEFAULT_PARTICIPANTS_COUNT, DEFAULT_VIDEO_PREFERENCE } from 'common/constants';
import { MeetingType } from 'lingopractices-models';
import { isEmpty } from 'lodash';

export class UpdateCreateMeetingData {
  static get action() {
    return createAction<TCreateMeetingData>('meetings/UPDATE_CREATE_DATA');
  }

  static get reducer() {
    return (
      draft: IMeetingsState,
      { payload }: ReturnType<typeof UpdateCreateMeetingData.action>,
    ) => {
      draft.createMeetingData = isEmpty(payload)
        ? ({
            meetingType: MeetingType.GoogleMeet,
            topicType: TopicEnum.OUR,
            participantsCount: DEFAULT_PARTICIPANTS_COUNT,
            preferVideo: DEFAULT_VIDEO_PREFERENCE,
            duration: DEFAULT_MEETING_DURATION
          } as TCreateMeetingData)
        : payload;
    };
  }
}
