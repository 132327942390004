import { createAction } from '@reduxjs/toolkit';
import { IUserBotsState } from '@store/user-bots/types';
import { ITelegramBotInfo } from 'lingopractices-models/src/main';
import { apply } from 'redux-saga/effects';

import { BotAvatarService } from '../../../../services/bot-avatar-service';

export class GetCurrentUserBotResult {
  static get action() {
    return createAction<ITelegramBotInfo | null>('user_bots/GET_CURRENT_USER_BOT_RESULT');
  }

  static get reducer() {
    return (
      draft: IUserBotsState,
      { payload }: ReturnType<typeof GetCurrentUserBotResult.action>,
    ) => {
      draft.currentUserBot = payload;

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof GetCurrentUserBotResult.action>) {
      const avatarService = new BotAvatarService();
      if (payload?.image?.linkPreview) {
        yield apply(avatarService, avatarService.initializeOrUpdate, [payload.image.linkPreview]);
      } else {
        yield apply(avatarService, avatarService.clear, []);
      }
    };
  }
}
