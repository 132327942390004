import { createAction } from '@reduxjs/toolkit';
import { INotificationsState } from '@store/notifications/types';

export class UpdateNotificationScheduleFailure {
  static get action() {
    return createAction('notifications/UPDATE_NOTIFICATION_SCHEDULE_FAILURE');
  }

  static get reducer() {
    return (draft: INotificationsState) => {
      draft.requests.updateNotificationSchedulePending = false;
      return draft;
    };
  }
}
