import { createAction } from '@reduxjs/toolkit';
import { IUserBotsState } from '@store/user-bots/types';

export class DeleteUserBotSuccess {
  static get action() {
    return createAction<string>('user_bots/DELETE_USER_BOT_SUCCESS');
  }

  static get reducer() {
    return (draft: IUserBotsState, { payload }: ReturnType<typeof DeleteUserBotSuccess.action>) => {
      draft.userBots = draft.userBots?.filter((bot) => bot.nickname !== payload);

      return draft;
    };
  }
}
