import React from 'react';

import './core/localization/day';
import './core/localization/i18n';
import { StyledEngineProvider } from '@mui/material/styles';
import { initSentry } from 'core/sentry';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/store';

import App from './App';

initSentry();

if (import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION' && import.meta.env.VITE_PROD_URL) {
  ReactGA.initialize(import.meta.env.VITE_GA_KEY);
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StyledEngineProvider injectFirst>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </StyledEngineProvider>,
);

document.addEventListener(
  'touchmove',
  (event: any) => {
    if (event.scale !== 1) {
      event.preventDefault();
    }
  },
  false,
);
