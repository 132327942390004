import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { ITopicsState } from '@store/topics/types';
import { addPendingRequest } from '@utils/cancel-request';
import { AxiosResponse, CancelTokenSource, HttpStatusCode } from 'axios';
import { logError } from 'core/sentry';
import { IAddQuestionsToTopicRequest } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { EditTopicSuccess } from './edit-topic-success';

export class EditTopic {
  static get action() {
    return createDeferredAction<IAddQuestionsToTopicRequest>('topics/EDIT_TOPIC');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.editTopicPending = true;
    };
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof EditTopic.action>): SagaIterator {
      const { topicId, questions } = payload;

      try {
        const response = EditTopic.httpRequest.call(
          yield call(() =>
            EditTopic.httpRequest.generator({ topicId, questions }, (token: CancelTokenSource) =>
              addPendingRequest(topicId, token),
            ),
          ),
        );

        if (response.status === HttpStatusCode.Ok) {
          yield put(EditTopicSuccess.action({ id: topicId, newQuestions: questions }));
          meta?.deferred.resolve();
        }
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, IAddQuestionsToTopicRequest>(
      MAIN_API.EDIT_TOPIC,
      HttpRequestMethod.Post,
    );
  }
}
