import { SeverityLevel } from '@sentry/react';

export enum Severity {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warning',
  Log = 'log',
  Info = 'info',
  Debug = 'debug',
  Critical = 'critical',
}

export interface LogExceptionOptions {
  source?: string;
  level?: Severity | SeverityLevel;
  tags?: Record<string, number | string | boolean | null | undefined>;
  data?: Record<string, unknown>;
}

export interface LogInfoOptions {
  message: string;
  category?: string;
  data?: Record<string, unknown>;
}

export type Exception = Error;
