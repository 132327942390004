import { all, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { CancelCreateMeeting } from './features/create-meeting/cancel-create-meeting';
import { CreateMeeting } from './features/create-meeting/create-meeting';
import { CreateMeetingSuccess } from './features/create-meeting/create-meeting-success';
import { EditMeeting } from './features/edit-meeting/edit-meeting';
import { EditMeetingNotification } from './features/edit-notification/edit-notification';
import { UpdateFilters } from './features/filters/update-filters';
import { GetMeetingById } from './features/get-meeting-by-id/get-meeting-by-id';
import { GetMeetings } from './features/get-meetings/get-meetings';
import { GetMyMeetings } from './features/get-my-meetings/get-my-meetings';
import { JoinMeeting } from './features/join-meeting/join-meeting';
import { LeaveMeeting } from './features/leave-meeting/leave-meeting';

export function* meetingSagas() {
  yield all([
    takeLatest(GetMeetings.action, GetMeetings.saga),
    takeLatest(GetMyMeetings.action, GetMyMeetings.saga),
    takeLatest(CreateMeeting.action, CreateMeeting.saga),
    takeLatest(CreateMeetingSuccess.action, CreateMeetingSuccess.saga),
    takeEvery(CancelCreateMeeting.action, CancelCreateMeeting.saga),
    takeLatest(JoinMeeting.action, JoinMeeting.saga),
    takeLatest(LeaveMeeting.action, LeaveMeeting.saga),
    takeEvery(GetMeetingById.action, GetMeetingById.saga),
    takeLeading(EditMeetingNotification.action, EditMeetingNotification.saga),
    takeEvery(EditMeeting.action, EditMeeting.saga),
    takeEvery(UpdateFilters.action, UpdateFilters.saga),
  ]);
}
