import { createAction } from '@reduxjs/toolkit';
import i18n from 'core/localization/i18n';
import { changeLanguage } from 'i18next';
import { IUser } from 'lingopractices-models';
import ReactGA from 'react-ga4';
import { SagaIterator } from 'redux-saga';
import { apply } from 'redux-saga/effects';
import { IProfileState } from 'store/profile/types';

export class GetProfileSuccess {
  static get action() {
    return createAction<IUser>('profile/GET_PROFILE_SUCCESS');
  }

  static get reducer() {
    return (draft: IProfileState, { payload }: ReturnType<typeof GetProfileSuccess.action>) => {
      draft.requests.getProfileInfoPending = false;
      draft.profileInfo = payload;

      return draft;
    };
  }

  static get saga() {
    return function* (action: ReturnType<typeof GetProfileSuccess.action>): SagaIterator {
      const { interfaceLanguage, id } = action.payload;

      const languageId = i18n.languages.some((lng) => interfaceLanguage?.id.includes(lng))
        ? interfaceLanguage?.id
        : 'en';

      yield apply(i18n, changeLanguage, [languageId]);

      yield apply(ReactGA, ReactGA.set, [{ userId: id }]);
    };
  }
}
