import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { getMyMeetingsSelector, getMyPastMeetingsSelector } from '@store/meetings/selectors';
import { IMeetingsState, MeetingPlace } from '@store/meetings/types';
import { getMeetingTypeByMeetingPlace } from '@utils/meeting-utils';
import { MY_MEETINGS_LIMITS } from '@utils/pagination-limits';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { Dayjs } from 'dayjs';
import {
  IGetMyMeetingsRequest,
  IMeeting,
  IPaginationParams,
  LanguageLevel,
  Sort,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { GetMyMeetingsFailure } from './get-my-meeting-failure';
import { GetMyMeetingsSuccess } from './get-my-meetings-success';
import { GetMyPastMeetingsSuccess } from './get-my-past-meetings-success';

export interface IGetMyMeetingsPayload {
  languageId: string | undefined;
  languageLevel: LanguageLevel | null;
  to: Dayjs | null;
  from: Dayjs | null;
  place: MeetingPlace | null;
  onlyPast?: boolean | null;
  initializedByScroll: boolean;
}

export class GetMyMeetings {
  static get action() {
    return createDeferredAction<IGetMyMeetingsPayload>('meetings/GET_MY_MEETINGS');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.getMyMeetingsPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof GetMyMeetings.action>): SagaIterator {
      const { onlyPast, languageId, languageLevel, to, from, place, initializedByScroll } = payload;

      const myMeetingList = onlyPast
        ? yield select(getMyPastMeetingsSelector)
        : yield select(getMyMeetingsSelector);

      const page: IPaginationParams = {
        offset: initializedByScroll ? myMeetingList.length : 0,
        limit: MY_MEETINGS_LIMITS,
      };

      try {
        const { data } = GetMyMeetings.httpRequest.call(
          yield call(() =>
            GetMyMeetings.httpRequest.generator({
              page,
              onlyPast: onlyPast ?? false,
              from: from ? from.toJSON() : null,
              to: to ? to.toJSON() : null,
              languageId,
              languageLevel,
              type: getMeetingTypeByMeetingPlace(place),
              sort: onlyPast ? Sort.Descending : Sort.Ascending,
            }),
          ),
        );

        const hasMore = data.length >= page.limit;

        if (onlyPast) {
          yield put(
            GetMyPastMeetingsSuccess.action({ data, hasMore, initializedByScroll }),
          );
        }

        if (!onlyPast) {
          yield put(GetMyMeetingsSuccess.action({ data, hasMore, initializedByScroll }));
          if (!hasMore)
            yield put(
              GetMyMeetings.action({
                initializedByScroll,
                onlyPast: true,
                from,
                to,
                languageId,
                languageLevel,
                place,
              }),
            );
        }
      } catch (e: any) {
        yield put(GetMyMeetingsFailure.action());
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IMeeting[]>, IGetMyMeetingsRequest>(
      MAIN_API.GET_MY_MEETINGS,
      HttpRequestMethod.Post,
    );
  }
}
