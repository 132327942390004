import { createAction } from '@reduxjs/toolkit';

import { IPurchaseState } from '../types';

export class ResetPurchaseState {
  static get action() {
    return createAction('purchase/RESET_PURCHASE_STATE');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.purchasePending = false;
      draft.requests.postPurchaseProfileUpdatePending = false;
      draft.paymentLinks.yookassa = undefined;

      return draft;
    };
  }
}
