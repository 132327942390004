import { createAction } from '@reduxjs/toolkit';
import { IProfileState } from '@store/profile/types';
import { logError } from 'core/sentry';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetFaqSuccess } from './get-faq-success';

export class GetFaq {
  static get action() {
    return createAction<string>('profile/GET_FAQS');
  }

  static get reducer() {
    return (draft: IProfileState) => {
      draft.requests.getFaqsPending = true;
    };
  }

  static get saga() {
    return function* ({ payload: locale }: ReturnType<typeof GetFaq.action>): SagaIterator {
      try {
        const data = yield call(fetch, `/faq/faq_${locale}.json`, { mode: 'no-cors' });
        const faqs = yield data.json();

        yield put(GetFaqSuccess.action({ [locale]: faqs }));
      } catch (e: any) {
        logError(e);
      }
    };
  }
}
