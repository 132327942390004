import { createAction } from '@reduxjs/toolkit';
import { SagaIterator } from 'redux-saga';
import { apply, put } from 'redux-saga/effects';
import { CreateAlertType } from 'screens/types';
import { AlertService } from 'services/alert-service';

import { SaveAlertStateSuccess } from './save-alert-success';

export class SaveAlertState {
  static get action() {
    return createAction<CreateAlertType | undefined>('steps/SAVE_ALERT_STATE');
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof SaveAlertState.action>): SagaIterator {
      const alertService = new AlertService();

      if (payload) {
        yield apply(alertService, alertService.initializeOrUpdate, [payload]);
      } else {
        yield apply(alertService, alertService.clear, []);
      }

      yield put(SaveAlertStateSuccess.action(payload));
    };
  }
}
