import { createAction } from '@reduxjs/toolkit';
import { IProfileState } from '@store/profile/types';

export class GetMyStatisticsFailure {
  static get action() {
    return createAction('profile/GET_MY_STATISTICS_FAILURE');
  }

  static get reducer() {
    return (draft: IProfileState) => {
      draft.requests.getMyStatisticsPending = false;
      return draft;
    };
  }
}
