import { TooltipType } from '@components/Tooltip/Tooltip';
import { createAction } from '@reduxjs/toolkit';
import { setNotificationAction } from '@store/app-notifications/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetProfile } from '@store/profile/features/get-profile/get-profile';
import { GetProfileSuccess } from '@store/profile/features/get-profile/get-profile-success';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import i18n from 'core/localization/i18n';
import { logError } from 'core/sentry';
import dayjs from 'dayjs';
import {
  ICreateYookassaPaymentResponse,
  IYookassaTransaction,
  YookassaStatus,
} from 'lingopractices-models';
import { SagaIterator, Task } from 'redux-saga';
import { call, cancel, delay, fork, put, select, take } from 'redux-saga/effects';

import { getPurchaseStartTimeInMsSelector } from '../selectors';
import { ResetPurchaseState } from '../start-finish-purchase/reset-purchase-state';
import { IPurchaseState } from '../types';

const PURCHASE_RETRY_TIMER_MS = 600000; // 10 mins
const PURCHASE_RETRY_DELAY = 5000;

function* checkPaymentStatus(paymentId: number) {
  const purchaseStartTimeInMs: number = yield select(getPurchaseStartTimeInMsSelector);
  let purchasePending = true;

  try {
    while (purchasePending && +dayjs() < purchaseStartTimeInMs + PURCHASE_RETRY_TIMER_MS) {
      const result = FinishPurchaseYookassa.httpRequest.call(
        yield call(() => FinishPurchaseYookassa.httpRequest.generator({ paymentId })),
      );

      if (
        result.data.status === YookassaStatus.Succeeded ||
        result.data.status === YookassaStatus.WaitingForCapture
      ) {
        yield put(GetProfile.action({}));
        yield take(GetProfileSuccess.action);
        yield put(
          setNotificationAction({
            id: dayjs().unix(),
            type: TooltipType.SUCCESS,
            // @ts-ignore: Need to update to i18next > 23, inconsistent eslint behavior
            text: `${i18n.t('plusSubscribe.thanks')} ${i18n.t('plusSubscribe.plusActive')}`,
          }),
        );
        purchasePending = false;
      }

      if (result.data.status === YookassaStatus.Canceled) {
        purchasePending = false;
      }

      yield delay(PURCHASE_RETRY_DELAY);
    }
  } catch (e: any) {
    logError(e);
    yield put(
      setNotificationAction({
        id: dayjs().unix(),
        type: TooltipType.ERROR,
        // @ts-ignore: Need to update to i18next > 23, inconsistent eslint behavior
        text: `${i18n.t('plusSubscribe.purchaseProcessingFailed', { provider: 'Yookassa' })}`,
      }),
    );
  } finally {
    yield put(ResetPurchaseState.action());
  }
}

export class FinishPurchaseYookassa {
  static get action() {
    return createAction<ICreateYookassaPaymentResponse>('purchase/FINISH_PURCHASE_YOOKASSA');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.purchasePending = true;
      draft.requests.purchaseStartTimeInMs = +dayjs();

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof FinishPurchaseYookassa.action>): SagaIterator {
      const { paymentId } = payload;

      const bgCheckTask: Task = yield fork(checkPaymentStatus, paymentId);
      yield take(ResetPurchaseState.action);
      yield cancel(bgCheckTask);
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IYookassaTransaction>, { paymentId: number }>(
      ({ paymentId }) => replaceInUrl(MAIN_API.CHECK_PAYMENT_ID_YOOKASSA, ['paymentId', paymentId]),
      HttpRequestMethod.Get,
    );
  }
}
