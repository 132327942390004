import { createAction } from '@reduxjs/toolkit';
import { initialGeneratedTopicState } from '@store/topics/reducer';
import { ITopicsState } from '@store/topics/types';
import { IGenerateQuestionsRequest } from 'lingopractices-models/src/main';

export class UpdateGeneratedTopicData {
  static get action() {
    return createAction<IGenerateQuestionsRequest | undefined>('topics/UPDATE_GENERATE_TOPIC_DATA');
  }

  static get reducer() {
    return (
      draft: ITopicsState,
      { payload }: ReturnType<typeof UpdateGeneratedTopicData.action>,
    ) => {
      draft.aiGeneratedTopicData = payload || initialGeneratedTopicState;
    };
  }
}
