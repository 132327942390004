import { createAction } from '@reduxjs/toolkit';

import { IPurchaseState } from '../types';

export class FetchAvailablePurchasesFailure {
  static get action() {
    return createAction('purchase/FETCH_AVAILABLE_PURCHASES_FAILURE');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.fetchAvailablePurchasesPending = false;

      return draft;
    };
  }
}
