import { SessionStorage } from '@utils/session-storage';
import { CreateAlertType } from 'screens/types';

export class AlertService {
  private readonly alertKey = 'alertState';

  private browserStorage = new SessionStorage(this.alertKey);

  private currentAlert: CreateAlertType = this.browserStorage.getObject<CreateAlertType>(
    this.alertKey,
  );

  public get alertState(): CreateAlertType {
    return this.currentAlert;
  }

  public initializeOrUpdate(data: CreateAlertType) {
    this.browserStorage.setObject<CreateAlertType>(this.alertKey, data);
    this.currentAlert = data;
  }

  public clear() {
    this.browserStorage.clear();
  }
}
