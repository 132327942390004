import { RootState } from 'store/store';

export const getProfileDataSelector = (state: RootState) => state.profile.profileInfo;
export const getProfileDataPendingSelector = (state: RootState) => state.profile.requests.getProfileInfoPending;

export const getInterfaceLanguageSelector = (state: RootState) =>
  state.profile.profileInfo?.interfaceLanguage;

export const getPracticeLanguageSelector = (state: RootState) =>
  state.profile.profileInfo?.practiceLanguage;

export const getLanguageLevelSelector = (state: RootState) =>
  state.profile.profileInfo?.languageLevel;

export const pendingUpdateUserSelector = (state: RootState) =>
  state.profile.requests.updateProfilePending;

export const isPlusUserSelector = (state: RootState) => state.profile.profileInfo?.isPlusUser;

export const isAuthorisedSelector = (state: RootState) => !!state.auth.securityToken;

export const themeSelector = (state: RootState) => state.profile.theme;

export const speechesSelector = (state: RootState) => state.profile.speeches;

export const speechesByLocaleSelector = (locale?: string) => (state: RootState) =>
  state.profile.speeches[locale || ''];

export const faqsByLocaleSelector = (locale?: string) => (state: RootState) =>
  state.profile.faqs[locale || ''];

export const pendingGetFaqsSelector = (state: RootState) => state.profile.requests.getFaqsPending;

export const hasGooglePermissionsSelector = (state: RootState) =>
  state.profile?.profileInfo?.userGoogleInfo?.hasCalendarPermission;

export const hasZoomPermissionsSelector = (state: RootState) =>
  state.profile.profileInfo?.userZoomInfo?.authorized;

export const authorizedGoogleSelector = (state: RootState) =>
  state.profile.profileInfo?.userGoogleInfo?.authorizedWithGoogle;

export const getMyStatisticsSelector = (state: RootState) => state.profile.statistics;

export const getMyStatisticsPendingSelector = (state: RootState) =>
  state.profile.requests.getMyStatisticsPending;

export const subscriptionExpiresAtSelector = (state: RootState) =>
  state.profile.profileInfo?.lastSubscription?.expireAt;

export const lastSubscriptionInfoSelector = (state: RootState) =>
  state.profile.profileInfo?.lastSubscription;

export const profileCountryCodeSelector = (state: RootState) =>
  state.profile.profileInfo?.countryCode;

export const isOrganizationSelector = (state: RootState) =>
  !!state.profile.profileInfo?.organization?.id;

export const practiceLimitsSelector = (state: RootState) =>
  state.profile.profileInfo?.practiceLimits;
