import { createAction } from '@reduxjs/toolkit';
import { IOpenAIState } from '@store/openAI/types';
import { PlusFeaturesRepository } from 'services/plus-features-service';

export class SetTopicGeneratorUses {
  static get action() {
    return createAction<number>('openAI/SET_TOPIC_GENERATOR_USES');
  }

  static get reducer() {
    return (draft: IOpenAIState, { payload }: ReturnType<typeof SetTopicGeneratorUses.action>) => {
      draft.usesLeft = payload;
      PlusFeaturesRepository.setTGUsesLeft(payload);
      return draft;
    };
  }
}
