import React from 'react';

import Logo from '@assets/img/lp-logo.png';
import { useTranslation } from 'react-i18next';

import { BotAvatarService } from '../../services/bot-avatar-service';
import { CountShowingAdultBannerService } from '../../services/count-showing-adult-banner-service';

import styles from './LogoScreen.module.scss';

const botAvatarLink = new BotAvatarService();
const countService = new CountShowingAdultBannerService();

const LogoScreen = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        <img src={botAvatarLink.avatar || Logo} alt='LingoPraxis Bot' className={styles.image} />
      </div>
      {countService.count < 2 && <p className={styles.banner}>{t('splash.banner')}</p>}
    </div>
  );
};

export default LogoScreen;
