import { all, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';

import { ChangeTheme } from './features/change-theme/change-theme';
import { CheckNickname } from './features/check-nickname/check-nickname';
import { CheckSignVisit } from './features/check-sign-visit/check-sign-visit';
import { GetAddressByLatLng } from './features/get-address-by-lat-lng.ts/get-address-by-lat-lng';
import { GetCurrentLocation } from './features/get-current-location/get-current-location';
import { GetFaq } from './features/get-faq/get-faq';
import { GetProfile } from './features/get-profile/get-profile';
import { GetProfileSuccess } from './features/get-profile/get-profile-success';
import { GetSpeeches } from './features/get-speeches/get-speeches';
import { GetMyStatistics } from './features/get-statistics/get-my-statistics';
import { GetTimeZone } from './features/get-time-zone/get-time-zone';
import { GetUserTheme } from './features/get-user-theme/get-user-theme';
import { PlatformAuth } from './features/platform-auth/platform-auth';
import { SendFeedback } from './features/send-feedback/send-feedback';
import { UpdateProfile } from './features/update-profile/update-profile';

export function* profileSagas() {
  yield all([
    takeLatest(GetProfile.action, GetProfile.saga),
    takeLatest(UpdateProfile.action, UpdateProfile.saga),
    takeLatest(ChangeTheme.action, ChangeTheme.saga),
    takeLeading(GetUserTheme.action, GetUserTheme.saga),
    takeLeading(GetProfileSuccess.action, GetProfileSuccess.saga),
    takeEvery(PlatformAuth.action, PlatformAuth.saga),
    takeEvery(GetCurrentLocation.action, GetCurrentLocation.saga),
    takeLatest(GetTimeZone.action, GetTimeZone.saga),
    takeEvery(GetSpeeches.action, GetSpeeches.saga),
    takeEvery(GetFaq.action, GetFaq.saga),
    takeEvery(CheckSignVisit.action, CheckSignVisit.saga),
    takeLatest(GetAddressByLatLng.action, GetAddressByLatLng.saga),
    takeLatest(CheckNickname.action, CheckNickname.saga),
    takeLatest(SendFeedback.action, SendFeedback.saga),
    takeLatest(GetMyStatistics.action, GetMyStatistics.saga),
  ]);
}
