import { getChatByIdSelector } from '@store/chats/selectors';
import { IChat, IChatsState } from '@store/chats/types';
import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { MESSAGES_LIMITS } from '@utils/pagination-limits';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import {
  IChatMessage,
  IGetMeetingChatMessagesQuery,
  IPaginationParams,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { GetChatFailure } from './get-chat-failure';
import { GetChatSuccess } from './get-chat-success';

export interface IGetChatPayload {
  chatId: number;
}

export class GetChat {
  static get action() {
    return createDeferredAction<IGetChatPayload>('chat/GET_CHAT');
  }

  static get reducer() {
    return (draft: IChatsState) => {
      draft.requests.getChatMessagesPending = true;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof GetChat.action>): SagaIterator {
      const { chatId } = payload;

      const chat: IChat = yield select(getChatByIdSelector(chatId));

      const page: IPaginationParams = {
        offset: chat.messages.length,
        limit: MESSAGES_LIMITS,
      };

      try {
        const { data } = GetChat.httpRequest.call(
          yield call(() =>
            GetChat.httpRequest.generator({ paginationParams: page, meetingId: chatId }),
          ),
        );

        const hasMore = data.length >= page.limit;

        yield put(GetChatSuccess.action({ chatId, messages: data, hasMore }));
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(GetChatFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IChatMessage[]>, IGetMeetingChatMessagesQuery>(
      MAIN_API.GET_CHAT,
      HttpRequestMethod.Post,
    );
  }
}
