import { createAction } from '@reduxjs/toolkit';
import { FileUploadState } from '@store/files/types';
import { IProgressUploadResponse } from '@utils/fileUploader/fileUploaderRequest';
import { produce } from 'immer';

export class UploadFileProgress {
  static get action() {
    return createAction<IProgressUploadResponse>('files/UPLOAD_FILE_PROGRESS');
  }

  static get reducer() {
    return produce(
      (draft: FileUploadState, { payload }: ReturnType<typeof UploadFileProgress.action>) => {
        const { uploadId, progress } = payload;
        draft[uploadId].progress = progress;
        return draft;
      },
    );
  }
}
