import { createAction } from '@reduxjs/toolkit';
import { IChatsState } from '@store/chats/types';

export class GetChatFailure {
  static get action() {
    return createAction('chat/GET_CHAT_FAILURE');
  }

  static get reducer() {
    return (draft: IChatsState) => {
      draft.requests.getChatMessagesPending = false;
    };
  }
}
