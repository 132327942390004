import { createAction } from '@reduxjs/toolkit';

import { IPurchaseState } from '../types';

export class StartPurchase {
  static get action() {
    return createAction('purchase/START_PURCHASE');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.purchasePending = true;
      draft.requests.postPurchaseProfileUpdatePending = false;

      return draft;
    };
  }
}
