import { configureStore } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import createSagaMiddleware from 'redux-saga';

import rootReducer, { TAppReducer } from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: false,
  stateTransformer: (state: TAppReducer) => {
    const transformedState = {
      ...state,
      profile: state.profile
        ? {
            ...state.profile,
            speeches: '[Omitted]',
          }
        : null,
      notifications: state.notifications
        ? {
            ...state.notifications,
            notificationsList: '[Omitted]',
          }
        : null,
      languages: '[Omitted]',
      topics: state.topics
        ? {
            ...state.topics,
            topics: '[Omitted]',
          }
        : null,
    };

    return transformedState;
  },
});

const enhancers = [sentryReduxEnhancer];

export const store = configureStore({
  reducer: rootReducer,
  middleware: [sagaMiddleware],
  enhancers,
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
