import { createAction } from '@reduxjs/toolkit';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { ILanguagesState } from '@store/languages/types';
import { GetProfileSuccess } from '@store/profile/features/get-profile/get-profile-success';
import { getInterfaceLanguageSelector, getProfileDataSelector } from '@store/profile/selectors';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ILanguage, IUser } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import { LanguageService } from 'services/LanguageService';

import { GetLanguagesFailure } from './get-languages-failure';
import { GetLanguagesSuccess } from './get-languages-success';

interface IGetLanguagesPayload {
  locale: string;
}

export class GetLanguages {
  static get action() {
    return createAction<IGetLanguagesPayload | undefined>('languages/GET_LANGUAGES');
  }

  static get reducer() {
    return (draft: ILanguagesState) => {
      draft.requests.getLanguagesPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof GetLanguages.action>): SagaIterator {
      const languageService = new LanguageService();
      const languages = languageService.languages || {};

      const user: IUser = yield select(getProfileDataSelector);

      let locale = 'en';

      if (payload) {
        locale = payload.locale;
      } else if (!user) {
        yield take(GetProfileSuccess.action);

        const interfaceLanguage: ILanguage = yield select(getInterfaceLanguageSelector);
        const { id } = interfaceLanguage;

        locale = id;
      }

      if (!languages[locale]) {
        try {
          const { data } = GetLanguages.httpRequest.call(
            yield call(() => GetLanguages.httpRequest.generator({ locale })),
          );

          yield put(GetLanguagesSuccess.action({ data, locale }));
        } catch (e: any) {
          yield put(GetLanguagesFailure.action());
          logError(e);
        }
      } else {
        yield put(GetLanguagesSuccess.action({ data: languages[locale], locale }));
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ILanguage[]>, { locale: string }>(
      ({ locale }) => replaceInUrl(MAIN_API.GET_LANGUAGES, ['locale', locale]),
      HttpRequestMethod.Get,
    );
  }
}
