import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { getProfileDataSelector } from '@store/profile/selectors';
import { ITopicsState } from '@store/topics/types';
import { addPendingRequest } from '@utils/cancel-request';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { logError } from 'core/sentry';
import { ICreateTopicRequest, ICreateTopicResponse, ITopic } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, select, put } from 'redux-saga/effects';

import { CreateTopicFailure } from './create-topic-failure';
import { CreateTopicSuccess } from './create-topic-success';

export class CreateTopic {
  static get action() {
    return createDeferredAction<ICreateTopicRequest>('topics/CREATE_TOPIC');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.createTopicPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof CreateTopic.action>): SagaIterator {
      const { name, questions } = payload;
      const user = yield select(getProfileDataSelector);

      try {
        const { data } = CreateTopic.httpRequest.call(
          yield call(() =>
            CreateTopic.httpRequest.generator({ name, questions }, (token: CancelTokenSource) =>
              addPendingRequest(user.id, token),
            ),
          ),
        );

        const { id } = data;

        const newTopic: ITopic = {
          id,
          name,
          questions,
        };

        yield put(CreateTopicSuccess.action(newTopic));
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(CreateTopicFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ICreateTopicResponse>, ICreateTopicRequest>(
      MAIN_API.CREATE_TOPIC,
      HttpRequestMethod.Post,
    );
  }
}
