import { combineReducers } from '@reduxjs/toolkit';

import alerts from './alerts/reducer';
import app_notifications from './app-notifications/reducer';
import auth from './auth/reducer';
import banners from './banners/reducer';
import chats from './chats/reducer';
import files from './files/reducer';
import internet from './internet/reducer';
import languages from './languages/reducer';
import meetings from './meetings/reducer';
import notifications from './notifications/reducer';
import openAI from './openAI/reducer';
import profile from './profile/reducer';
import purchases from './purchase/reducer';
import topics from './topics/reducer';
import userBots from './user-bots/reducer';
import users from './users/reducer';

const rootReducer = combineReducers({
  auth,
  profile,
  meetings,
  topics,
  languages,
  app_notifications,
  alerts,
  internet,
  chats,
  users,
  notifications,
  banners,
  purchases,
  openAI,
  userBots,
  files,
});

export type TAppReducer = ReturnType<typeof rootReducer>;

export default rootReducer;
