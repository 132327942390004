import UseLoadScriptOptions from '@react-google-maps/api';
import { LanguageLevel, MeetingType, PushNotificationTypes } from 'lingopractices-models';

// dayjs formats
export const HOUR_MINUTE = 'HH:mm';
export const DAY_MONTH_YEAR = 'DD.MM.YYYY';
export const LOCALIZED_TIME = 'LLL';
export const FULL_DATE = 'YYYY-MM-DDTHH:mm:ss';
export const DAY_NAME = 'ddd';
export const WEEK_DAYS = 7;
export const FULL_MONTH_YEAR = 'MMMM YYYY';
export const DAY_FULL_MONTH = 'DD MMMM';

export const interfaceLanguagesIds: TInterfaceLanguages[] = ['ru', 'en', 'uk', 'be', 'es'];

export type TInterfaceLanguages = 'ru' | 'en' | 'uk' | 'be' | 'es';

export type TLearningLanguages =
  | 'ar'
  | 'be'
  | 'de'
  | 'en'
  | 'es'
  | 'fr'
  | 'hi'
  | 'ja'
  | 'pl'
  | 'pt'
  | 'ru'
  | 'uk'
  | 'zh'
  | 'it';

export const allLevels = [
  { id: LanguageLevel.Beginner },
  { id: LanguageLevel.PreIntermediate },
  { id: LanguageLevel.Intermediate },
  { id: LanguageLevel.UpperIntermediate },
  { id: LanguageLevel.Advanced },
  { id: LanguageLevel.Proficiency },
];

export const allLevelCodes = {
  [LanguageLevel.Beginner]: 'A1',
  [LanguageLevel.PreIntermediate]: 'A2',
  [LanguageLevel.Intermediate]: 'B1',
  [LanguageLevel.UpperIntermediate]: 'B2',
  [LanguageLevel.Advanced]: 'C1',
  [LanguageLevel.Proficiency]: 'C2',
};

export const allPushNotifications = [
  { id: PushNotificationTypes.MeetingCreated },
  { id: PushNotificationTypes.UserJoinedMeeting },
  { id: PushNotificationTypes.UserLeftMeeting },
  { id: PushNotificationTypes.MeetingDeleted },
  { id: PushNotificationTypes.ChatMessageCreated },
  { id: PushNotificationTypes.MeetingStartsSoon },
];

export const participantsCountIds: number[] = [2, 3, 4, 5, 6, 7, 8, 9, 10];

export const MIN_PARTICIPANTS_COUNT = 2;
export const DEFAULT_PARTICIPANTS_COUNT = MIN_PARTICIPANTS_COUNT + 2;
export const MAX_PARTICIPANTS_COUNT = 10;
export const MEETING_PARTICIPANTS_STEP = 1;
export const MAX_PARTICIPANTS_COUNT_OFFLINE = 50;
export const MAX_LENGTH_ABOUT_ME_INFO = 500;

export const MIN_MEETING_DURATION = 30;
export const MAX_MEETING_DURATION = 120;
export const DEFAULT_MEETING_DURATION = 60;
export const MEETING_DURATION_STEP = 15;
export const DEFAULT_VIDEO_PREFERENCE = false;

export const topicIds = [1, 2];

type OnlineMeetingTypes = MeetingType.Custom | MeetingType.GoogleMeet | MeetingType.Zoom;

export const onlineMeetingTypes: OnlineMeetingTypes[] = [
  MeetingType.GoogleMeet,
  MeetingType.Zoom,
  MeetingType.Custom,
];

export const LINGO_PRACTICES_TELEGRAM_PATH = 'https://t.me/lingopractices';

export const TYPING_DURATION = 200;
export const ANIMATION_DURATION = 200;
export const NOTIFICATION_DURATION = 4000;
export const SCROLL_TOP = 85;
export const SCROLL_DOWN = 1;
export const SWIPE_WIDTH = 30;
export const DELAY_STARTED_MEETING = 1000;

export const GOOGLE_API_OPTIONS: UseLoadScriptOptions.LoadScriptProps = {
  id: 'google-map-script',
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_API_KEY,
  libraries: ['places'],
};

export const NAME_MIN_LENGTH = 5;

export const MEETING_DETAILS_PATH = '/meeting_details';

export enum OrganizationLinks {
  Website = 'website',
  Instagram = 'instagram',
  Facebook = 'facebook',
  Telegram = 'telegram',
  Whatsapp = 'whatsapp',
  Phone = 'phone',
}

export const allOrganizationLinks = [
  { id: OrganizationLinks.Website },
  { id: OrganizationLinks.Instagram },
  { id: OrganizationLinks.Facebook },
  { id: OrganizationLinks.Telegram },
  { id: OrganizationLinks.Whatsapp },
  { id: OrganizationLinks.Phone },
];

export const LinksStringMap = {
  [OrganizationLinks.Instagram]: 'https://www.instagram.com/',
  [OrganizationLinks.Facebook]: 'https://www.facebook.com/',
  [OrganizationLinks.Telegram]: 'https://t.me/',
  [OrganizationLinks.Phone]: 'tel:+',
  [OrganizationLinks.Website]: '',
  [OrganizationLinks.Whatsapp]: 'https://wa.me/',
};

export enum PermissionTypes {
  Granted = 'granted',
  NotDetermined = 'notDetermined',
  Denied = 'denied',
}

export enum MeetingDate {
  Today = 1,
  Tomorrow = 2,
}
