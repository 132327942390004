import { TooltipType } from '@components/Tooltip/Tooltip';
import { createAction } from '@reduxjs/toolkit';
import { setNotificationAction } from '@store/app-notifications/actions';
import { GetProfile } from '@store/profile/features/get-profile/get-profile';
import { GetProfileSuccess } from '@store/profile/features/get-profile/get-profile-success';
import { isPlusUserSelector } from '@store/profile/selectors';
import i18n from 'core/localization/i18n';
import dayjs from 'dayjs';
import { delay, put, select, take } from 'redux-saga/effects';

import { IPurchaseState } from '../types';

import { ResetPurchaseState } from './reset-purchase-state';

const PROFILE_RETRY_NUMBER = 6;
const PROFILE_RETRY_DELAY = 3000;

export class FinishPurchase {
  static get action() {
    return createAction('purchase/FINISH_PURCHASE');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.purchasePending = false;
      draft.requests.postPurchaseProfileUpdatePending = true;

      return draft;
    };
  }

  static get saga() {
    return function* () {
      let isPlusUser: boolean = yield select(isPlusUserSelector);
      let retries = 0;

      while (!isPlusUser && retries < PROFILE_RETRY_NUMBER) {
        yield delay(PROFILE_RETRY_DELAY * retries);
        yield put(GetProfile.action);
        yield take(GetProfileSuccess.action);
        retries += 1;
        isPlusUser = yield select(isPlusUserSelector);

        if (isPlusUser) {
          yield put(
            setNotificationAction({
              id: dayjs().unix(),
              type: TooltipType.SUCCESS,
              // @ts-ignore: Need to update to i18next > 23, inconsistent eslint behavior
              text: `${i18n.t('plusSubscribe.thanks')} ${i18n.t('plusSubscribe.plusActive')}`,
            }),
          );
        }
      }

      yield put(ResetPurchaseState.action());
    };
  }
}
