import React, { useCallback, useEffect, useRef } from 'react';

import CloseIcon from '@assets/icons/close.svg?react';
import InfoIcon from '@assets/icons/info.svg?react';
import Portal from '@components/Portal/Portal';
import { useActionWithDispatch } from '@hooks/use-action-with-dispatch';
import { removeNotificationAction } from '@store/app-notifications/actions';
import { currentNotificationSelector } from '@store/app-notifications/selectors';
import { NOTIFICATION_DURATION } from 'common/constants';
import { debounce } from 'lodash';
import { useSelector } from 'react-redux';

import styles from './Tooltip.module.scss';

export const enum TooltipType {
  INFO,
  ERROR,
  SUCCESS,
}

const Tooltip: React.FC = () => {
  const currentNotification = useSelector(currentNotificationSelector);
  const removeNotification = useActionWithDispatch(removeNotificationAction);
  const containerRef = useRef<HTMLDivElement>(null);
  const notificationTextRef = useRef<HTMLHeadingElement>(null);

  const animatedClose = useCallback(() => {
    if (currentNotification) {
      containerRef.current?.classList.add(styles.closeTooltip);

      if (containerRef.current) {
        containerRef.current.onanimationend = () => removeNotification(currentNotification.id);
      }
    }
  }, [currentNotification, removeNotification]);

  const closeToolTip = debounce(animatedClose, NOTIFICATION_DURATION);

  const handleClose = useCallback(() => {
    closeToolTip.cancel();
    animatedClose();
  }, [closeToolTip, animatedClose]);

  useEffect(() => {
    if (currentNotification) {
      containerRef.current?.classList.add(styles.openTooltip);
      closeToolTip();

      if (notificationTextRef.current) {
        const paragraph = window.document.createElement('p');
        paragraph.innerHTML = currentNotification.text;
        notificationTextRef.current.append(paragraph);
      }
    }
  }, [closeToolTip, currentNotification, notificationTextRef]);

  return (
    <Portal>
      <div className={styles.container} ref={containerRef}>
        <div className={styles.icon}>
          <InfoIcon />
        </div>
        <div ref={notificationTextRef} className={styles.content} />
        <div className={styles.close}>
          <CloseIcon onClick={handleClose} />
        </div>
      </div>
    </Portal>
  );
};

export default Tooltip;
