import { createAction } from '@reduxjs/toolkit';
import { FileUploadState } from '@store/files/types';
import { uploadFileSaga } from '@utils/fileUploader';
import {
  IErrorUploadResponse,
  IFileUploadRequest,
  IProgressUploadResponse,
} from '@utils/fileUploader/fileUploaderRequest';
import { produce } from 'immer';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { UploadFileFailure } from './upload-file-failure';
import { UploadFileProgress } from './upload-file-progress';
import { UploadFileSuccess } from './upload-file-success';

export type IUploadFileActionPayload<TResponse = never> = {
  file: File;
  fileName: string;
  uploadId: string;
  url: string;
  progressAction?: (response: IProgressUploadResponse) => void;
  errorAction?: (response: IErrorUploadResponse) => void;
  completedAction: (response: TResponse) => void;
};

export class UploadFile {
  static get action() {
    return createAction<IUploadFileActionPayload>('files/UPLOAD_FILE');
  }

  static get reducer() {
    return produce((draft: FileUploadState, { payload }: ReturnType<typeof UploadFile.action>) => {
      const { uploadId, fileName } = payload;

      draft[uploadId] = {
        progress: 0,
        fileName,
      };
      return draft;
    });
  }

  static get saga() {
    // eslint-disable-next-line func-names
    return function* uploadFile({ payload }: ReturnType<typeof UploadFile.action>): SagaIterator {
      const { url, file, uploadId, progressAction, errorAction, completedAction } = payload;

      const uploadRequest: IFileUploadRequest<never> = {
        uploadId,
        file,
        url,
        *progressCallback(response) {
          if (progressAction) {
            yield call(progressAction, response);
          }

          yield put(UploadFileProgress.action(response));
        },
        *errorCallback(response) {
          if (errorAction) {
            yield call(errorAction, response);
          }

          yield put(UploadFileFailure.action(response.uploadId));
        },
        *completedCallback(response) {
          if (completedAction) {
            yield call(completedAction, response.data);
          }

          yield put(UploadFileSuccess.action(response.uploadId));
        },
      };
      yield call(uploadFileSaga, uploadRequest);
    };
  }
}
