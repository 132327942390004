import { RootState } from '@store/store';

export const getTopicsSelector = (state: RootState) => state.topics.topics;

export const getTopicsPendingSelector = (state: RootState) =>
  state.topics.requests.getTopicsPending;

export const getTopicsHasMoreSelector = (state: RootState) => state.topics.hasMore;

export const getTopicByIdSelector = (id?: number) => (state: RootState) =>
  state.topics.topics.find((topic) => topic.id === id);

export const getCreateTopicPendingSelector = (state: RootState) =>
  state.topics.requests.createTopicPending;

export const getEditTopicPendingSelector = (state: RootState) =>
  state.topics.requests.editTopicPending;

export const getTopicByIdPendingSelector = (state: RootState) =>
  state.topics.requests.getTopicByIdPending;

export const getTagsSelector = (state: RootState) => state.topics.tags;

export const getTagsPendingSelector = (state: RootState) => state.topics.requests.getTagsPending;

export const getTopicsByTagIdSelector = (state: RootState) => state.topics.topicsByTagId;

export const aiGeneratedTopicDataSelector = (state: RootState) => state.topics.aiGeneratedTopicData;
