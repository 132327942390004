import { createAction } from '@reduxjs/toolkit';
import { IUsersState } from '@store/users/types';
import { IStatistic } from 'lingopractices-models';

export class GetStatisticsByIdSuccess {
  static get action() {
    return createAction<IStatistic>('users/GET_STATISTICS_BY_ID_SUCCESS');
  }

  static get reducer() {
    return (
      draft: IUsersState,
      { payload }: ReturnType<typeof GetStatisticsByIdSuccess.action>,
    ) => {
      draft.requests.getStatisticsByUserIdPending = false;
      draft.statisticsByUserId = payload;

      return draft;
    };
  }
}
