export class CloudStorage {
  private readonly prefix: string;

  constructor(prefix: string) {
    this.prefix = `${prefix}_`;
  }

  public setObject<T>(key: string, value: T): void {
    this.setItem(key, JSON.stringify(value));
  }

  public async getObject<T>(key: string): Promise<T> {
    const item: string = await this.getItem(key);

    return JSON.parse(item);
  }

  public getItem(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      window.Telegram.WebApp.CloudStorage.getItem(this.getKey(key), (error, value) => {
        if (value) {
          resolve(value);
        } else {
          reject(error);
        }
      });
    });
  }

  public setItem(key: string, value: string): void {
    window.Telegram.WebApp.CloudStorage.setItem(this.getKey(key), value);
  }

  public removeItem(key: string): void {
    window.Telegram.WebApp.CloudStorage.removeItem(this.getKey(key));
  }

  private getKey(key: string): string {
    return this.prefix + key;
  }
}
