import { createAction } from '@reduxjs/toolkit';
import { getChatByIdDraftSelector } from '@store/chats/selectors';
import { IChatsState } from '@store/chats/types';
import dayjs from 'dayjs';
import { IChatMessage } from 'lingopractices-models';
import { uniqBy } from 'lodash';

export interface IGetChatSuccessPayload {
  chatId: number;
  hasMore: boolean;
  messages: IChatMessage[];
}

export class GetChatSuccess {
  static get action() {
    return createAction<IGetChatSuccessPayload>('chat/GET_CHAT_SUCCESS');
  }

  static get reducer() {
    return (draft: IChatsState, { payload }: ReturnType<typeof GetChatSuccess.action>) => {
      const { chatId, messages, hasMore } = payload;

      const chat = getChatByIdDraftSelector(chatId, draft);

      draft.chats[chatId] = {
        messages: uniqBy(
          [...chat.messages, ...messages].sort(
            (a, b) => dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
          ),
          'id',
        ),
        hasMore,
      };

      draft.requests.getChatMessagesPending = false;
    };
  }
}
