import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export class CheckNickname {
  static get action() {
    return createDeferredAction<{ nickname: string }>('profile/CHECK_NICKNAME');
  }

  static get saga() {
    return function* (action: ReturnType<typeof CheckNickname.action>): SagaIterator {
      const { nickname } = action.payload;

      try {
        CheckNickname.httpRequest.call(
          yield call(() => CheckNickname.httpRequest.generator({ nickname })),
        );

        action.meta?.deferred.resolve(true);
      } catch (e) {
        action.meta?.deferred.resolve(false);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<string[]>, { nickname: string }>(
      ({ nickname }: { nickname: string }) =>
        replaceInUrl(MAIN_API.CHECK_NICKNAME, ['nickname', nickname]),
      HttpRequestMethod.Get,
    );
  }
}
