import { createAction } from '@reduxjs/toolkit';

import { IUsersState } from '../../types';

export class GetStatisticsByIdFailure {
  static get action() {
    return createAction('users/GET_STATISTICS_BY_ID_FAILURE');
  }

  static get reducer() {
    return (draft: IUsersState) => {
      draft.requests.getStatisticsByUserIdPending = false;

      return draft;
    };
  }
}
