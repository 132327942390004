import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';

export class GetTopicByIdFailure {
  static get action() {
    return createAction('topics/GET_TOPIC_BY_ID_FAILURE');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.getTopicByIdPending = false;
    };
  }
}
