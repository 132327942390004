import { createAction } from '@reduxjs/toolkit';
import { IUpdateMeetingRequest } from 'lingopractices-models';

import { IMeetingsState } from '../../types';

interface IEditMeetingSuccessPayload {
  updatedMeeting: IUpdateMeetingRequest;
}

export class EditMeetingSuccess {
  static get action() {
    return createAction<IEditMeetingSuccessPayload>('meetings/EDIT_MEETING_SUCCESS');
  }

  static get reducer() {
    return (draft: IMeetingsState, { payload }: ReturnType<typeof EditMeetingSuccess.action>) => {
      const { updatedMeeting } = payload;
      const myMeetingIdx = draft.myMeetings.meetingList.findIndex(
        (meeting) => meeting.id === updatedMeeting.meetingId,
      );

      draft.myMeetings.meetingList[myMeetingIdx] = {
        ...draft.myMeetings.meetingList[myMeetingIdx],
        meetingDate: updatedMeeting.meetingAt as string,
        maxParticipantsCount: updatedMeeting.peopleNumber as number,
        topicName: updatedMeeting.topicName as string,
        topicDescription: updatedMeeting.topicDescription as string,
        comment: updatedMeeting.comment || '',
        preferVideo: updatedMeeting.preferVideo as boolean,
        duration: updatedMeeting.duration as number,
        customMeetingInfo: updatedMeeting.customMeetingInfo,
      };

      const allMeetingIdx = draft.meetings.meetingList.findIndex(
        (meeting) => meeting.id === updatedMeeting.meetingId,
      );

      draft.meetings.meetingList[allMeetingIdx] = {
        ...draft.myMeetings.meetingList[myMeetingIdx],
        meetingDate: updatedMeeting.meetingAt as string,
        maxParticipantsCount: updatedMeeting.peopleNumber as number,
        topicName: updatedMeeting.topicName as string,
        topicDescription: updatedMeeting.topicDescription || '',
        comment: updatedMeeting.comment || '',
        preferVideo: updatedMeeting.preferVideo as boolean,
        duration: updatedMeeting.duration as number,
        customMeetingInfo: updatedMeeting.customMeetingInfo,
      };

      if (draft.myMeetings.selectedMeeting) {
        draft.myMeetings.selectedMeeting = {
          ...draft.myMeetings.selectedMeeting,
          meetingDate: updatedMeeting.meetingAt as string,
          maxParticipantsCount: updatedMeeting.peopleNumber as number,
          topicName: updatedMeeting.topicName as string,
          topicDescription: updatedMeeting.topicDescription || '',
          comment: updatedMeeting.comment || '',
          preferVideo: updatedMeeting.preferVideo as boolean,
          duration: updatedMeeting.duration as number,
          customMeetingInfo: updatedMeeting.customMeetingInfo,
        };
      }

      draft.requests.editMeetingPending = false;

      return draft;
    };
  }
}
