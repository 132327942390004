import * as Sentry from '@sentry/react';
import { AuthInit } from '@store/auth/features/init-auth/init-auth';
import { createDeferredAction } from '@store/common/actions';
import { ResolveUserLocation } from '@store/initiation/features/resolve-user-location';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { IUser } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { httpRequestFactory } from 'store/common/http-request-factory';
import { HttpRequestMethod } from 'store/common/http-request-method';
import { MAIN_API } from 'store/common/path';
import { IProfileState } from 'store/profile/types';

import { GetProfileSuccess } from './get-profile-success';

export class GetProfile {
  static get action() {
    return createDeferredAction('profile/GET_PROFILE');
  }

  static get reducer() {
    return (draft: IProfileState) => {
      draft.requests.getProfileInfoPending = true;
    };
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetProfile.action>): SagaIterator {
      try {
        const { data } = GetProfile.httpRequest.call(
          yield call(() => GetProfile.httpRequest.generator()),
        );

        yield put(GetProfileSuccess.action(data));

        const { city, countryName, countryCode, timeZoneId, id, username, userGoogleInfo } = data;

        if (!(city && countryName && countryCode && timeZoneId)) {
          try {
            yield put(ResolveUserLocation.action());
          } catch (e: any) {
            logError(e);
            meta?.deferred.reject(e);
            return;
          }
        }

        if (id || username || userGoogleInfo?.email)
          Sentry.setUser({
            email: userGoogleInfo?.email,
            id,
            username,
          });

        yield put(AuthInit.action());
        meta?.deferred.resolve(data);
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IUser>, null>(MAIN_API.GET_USER, HttpRequestMethod.Get);
  }
}
