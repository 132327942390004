import { createReducer } from '@reduxjs/toolkit';
import { GetNotificationSchedule } from '@store/notifications/features/notification-schedule/get-notification-schedule/get-notification-schedule';
import { GetNotificationScheduleFailure } from '@store/notifications/features/notification-schedule/get-notification-schedule/get-notification-schedule-failure';
import { GetNotificationScheduleSuccess } from '@store/notifications/features/notification-schedule/get-notification-schedule/get-notification-schedule-success';
import { UpdateNotificationSchedule } from '@store/notifications/features/notification-schedule/update-notification-schedule/update-notification-schedule';
import { UpdateNotificationScheduleFailure } from '@store/notifications/features/notification-schedule/update-notification-schedule/update-notification-schedule-failure';
import { UpdateNotificationScheduleSuccess } from '@store/notifications/features/notification-schedule/update-notification-schedule/update-notification-schedule-success';
import { PermissionTypes } from 'common/constants';

import { INotificationsState } from './types';

export const defaultNotificationsState: INotificationsState = {
  isNotificationsAsked: false,
  notificationPermissions: PermissionTypes.NotDetermined,
  notificationsList: [],
  notificationSchedule: null,
  requests: {
    getNotificationSchedulePending: false,
    updateNotificationSchedulePending: false,
    checkNotificationPermOnLoginPending: false,
  },
};

const notifications = createReducer(defaultNotificationsState, (builder) =>
  builder
    .addCase(GetNotificationSchedule.action, GetNotificationSchedule.reducer)
    .addCase(GetNotificationScheduleSuccess.action, GetNotificationScheduleSuccess.reducer)
    .addCase(GetNotificationScheduleFailure.action, GetNotificationScheduleFailure.reducer)
    .addCase(UpdateNotificationSchedule.action, UpdateNotificationSchedule.reducer)
    .addCase(UpdateNotificationScheduleSuccess.action, UpdateNotificationScheduleSuccess.reducer)
    .addCase(UpdateNotificationScheduleFailure.action, UpdateNotificationScheduleFailure.reducer),
);

export default notifications;
