import { createReducer } from '@reduxjs/toolkit';
import { FileUploadState } from '@store/files/types';

import { UploadFile } from './features/upload-file/upload-file';
import { UploadFileFailure } from './features/upload-file/upload-file-failure';
import { UploadFileProgress } from './features/upload-file/upload-file-progress';
import { UploadFileSuccess } from './features/upload-file/upload-file-success';

export const fileUploadInitialState: FileUploadState = {};

const reducer = createReducer<FileUploadState>(fileUploadInitialState, (builder) =>
  builder
    .addCase(UploadFile.action, UploadFile.reducer)
    .addCase(UploadFileProgress.action, UploadFileProgress.reducer)
    .addCase(UploadFileSuccess.action, UploadFileSuccess.reducer)
    .addCase(UploadFileFailure.action, UploadFileFailure.reducer),
);

export default reducer;
