import { GetBanners } from '@store/banners/features/get-banners/get-banners';
import { MarkBannerWatched } from '@store/banners/features/mark-banner-watched/mark-banner-watched';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

export function* bannersSagas() {
  yield all([
    takeEvery(GetBanners.action, GetBanners.saga),
    takeLatest(MarkBannerWatched.action, MarkBannerWatched.saga),
  ]);
}
