import { IMeetingFilter } from '@store/meetings/types';
import { BrowserStorage } from '@utils/BrowserStorage';

export interface IMeetingsFilters {
  myMeetingsFilters?: IMeetingFilter;
  allMeetingsFilters?: IMeetingFilter;
}

export class MeetingsFiltersService {
  private readonly filtersKey = 'filters';

  private browserStorage = new BrowserStorage(this.filtersKey);

  private currentMeetingsFilters: IMeetingsFilters =
    this.browserStorage.getObject<IMeetingsFilters>(this.filtersKey);

  public get filters(): IMeetingsFilters {
    return this.currentMeetingsFilters;
  }

  public initializeOrUpdate(filters: IMeetingsFilters) {
    this.browserStorage.setObject<IMeetingsFilters>(this.filtersKey, filters);
    this.currentMeetingsFilters = filters;
  }

  public clear() {
    this.browserStorage.clear();
  }
}
