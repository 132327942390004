import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { ITopicsState } from '@store/topics/types';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ITopic } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetTopicByIdFailure } from './get-topic-by-id-failure';
import { GetTopicByIdSuccess } from './get-topic-by-id-success';

export class GetTopicById {
  static get action() {
    return createDeferredAction<string>('topics/GET_TOPIC_BY_ID');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.getTopicByIdPending = true;
    };
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof GetTopicById.action>): SagaIterator {
      try {
        const { data } = GetTopicById.httpRequest.call(
          yield call(() => GetTopicById.httpRequest.generator(payload)),
        );

        meta?.deferred.resolve(data);
        yield put(GetTopicByIdSuccess.action(data));
      } catch (e: any) {
        yield put(GetTopicByIdFailure.action());
        meta?.deferred.reject();
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ITopic>, string>(
      (topicId) => replaceInUrl(MAIN_API.GET_TOPIC_BY_ID, ['topicId', topicId]),
      HttpRequestMethod.Get,
    );
  }
}
