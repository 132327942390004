import { createReducer } from '@reduxjs/toolkit';

import { SetTopicGeneratorUses } from './features/topic-generator-uses/set-topic-generator-uses';
import { SetTopicGeneratorUsesLocal } from './features/topic-generator-uses/set-topic-generator-uses-local';
import { IOpenAIState } from './types';

const initialState: IOpenAIState = {
  usesLeft: undefined,
};

const reducer = createReducer<IOpenAIState>(initialState, (builder) => {
  builder
    .addCase(SetTopicGeneratorUses.action, SetTopicGeneratorUses.reducer)
    .addCase(SetTopicGeneratorUsesLocal.action, SetTopicGeneratorUsesLocal.reducer);
});

export default reducer;
