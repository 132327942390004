import { createAction } from '@reduxjs/toolkit';

import { IPurchaseState } from '../types';

export class GetPaymentLinkTelegramFailure {
  static get action() {
    return createAction('purchase/GET_PAYMENT_LINK_TELEGRAM_FAILURE');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.getPaymentLinkPending = false;

      return draft;
    };
  }
}
