import { createAction } from '@reduxjs/toolkit';
import { IGetMeetingSuccessPayload, IMeetingsState } from '@store/meetings/types';
import { uniqBy } from 'lodash';

export class GetPastMeetingsSuccess {
  static get action() {
    return createAction<IGetMeetingSuccessPayload>(
      'meetings/GET_PAST_MEETINGS_SUCCESS',
    );
  }

  static get reducer() {
    return (
      draft: IMeetingsState,
      { payload }: ReturnType<typeof GetPastMeetingsSuccess.action>,
    ) => {
      const { data, hasMore, initializedByScroll } = payload;

      draft.requests.getMeetingsPending = false;
      draft.meetings.hasMorePast = hasMore;
      draft.meetings.meetingListPast = uniqBy(
        initializedByScroll ? [...draft.meetings.meetingListPast, ...data] : data,
        'id',
      );

      return draft;
    };
  }
}
