import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetCurrentUserBotResult } from '@store/user-bots/features/get-current-user-bot/get-current-user-bot-result';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ITelegramBotInfo } from 'lingopractices-models/src/main';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export class GetCurrentUserBot {
  static get action() {
    return createDeferredAction('user_bots/GET_CURRENT_USER_BOT');
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetCurrentUserBot.action>): SagaIterator {
      try {
        const { data } = GetCurrentUserBot.httpRequest.call(
          yield call(() => GetCurrentUserBot.httpRequest.generator()),
        );

        yield put(GetCurrentUserBotResult.action(data || null));
        meta?.deferred.resolve(data);
      } catch (e: any) {
        yield put(GetCurrentUserBotResult.action(null));
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ITelegramBotInfo>, undefined>(
      MAIN_API.GET_CURRENT_USER_BOT,
      HttpRequestMethod.Get,
    );
  }
}
