import { all, takeEvery } from 'redux-saga/effects';

import { CheckNewMessages } from './features/check-new-messages/check-new-messages';
import { CreateMessage } from './features/create-message/create-message';
import { GetChat } from './features/get-chat/get-chat';

export function* chatsSagas() {
  yield all([
    takeEvery(GetChat.action, GetChat.saga),
    takeEvery(CreateMessage.action, CreateMessage.saga),
    takeEvery(CheckNewMessages.action, CheckNewMessages.saga),
  ]);
}
