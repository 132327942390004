import { RootState } from 'store/store';

export const subscriptionsSelector = (state: RootState) => state.purchases.subscriptions;
export const meetingPackagesSelector = (state: RootState) => state.purchases.meetingPackages;

export const paymentLinkYookassaSelector = (state: RootState) =>
  state.purchases.paymentLinks.yookassa;
export const getPaymentLinkPendingSelector = (state: RootState) =>
  state.purchases.requests.getPaymentLinkPending;
export const purchasePendingSelector = (state: RootState) =>
  state.purchases.requests.purchasePending;
export const getPurchaseStartTimeInMsSelector = (state: RootState) =>
  state.purchases.requests.purchaseStartTimeInMs;
