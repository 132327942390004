import { createAction } from '@reduxjs/toolkit';
import { IAlertState } from '@store/alerts/types';

export class DeleteAlertSuccess {
  static get action() {
    return createAction<number>('notifications/DELETE_ALERT_SUCCESS');
  }

  static get reducer() {
    return (draft: IAlertState, { payload }: ReturnType<typeof DeleteAlertSuccess.action>) => {
      draft.requests.deleteNotificationsPending = false;

      draft.notificationsPreferecnces = draft.notificationsPreferecnces?.filter(
        (alert) => alert.id !== payload,
      );

      return draft;
    };
  }
}
