export const getParamFromUrl = (url: string, param: string) => {
  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);
  return searchParams.get(param);
};

export const getTokenFromUrl = () => {
  const token = getParamFromUrl(window.location.href, 'token');

  return token ? atob(token) : '';
};

export const getStartParamFromUrl = () => {
  const startParam = getParamFromUrl(window.location.href, 'start_param');

  return startParam || '';
};
