import { createAction } from '@reduxjs/toolkit';
import { IOpenAIState } from '@store/openAI/types';

export class SetTopicGeneratorUsesLocal {
  static get action() {
    return createAction<number>('openAI/SET_TOPIC_GENERATOR_USES_LOCAL');
  }

  static get reducer() {
    return (
      draft: IOpenAIState,
      { payload }: ReturnType<typeof SetTopicGeneratorUsesLocal.action>,
    ) => {
      draft.usesLeft = payload;
      return draft;
    };
  }
}
