import { createAction } from '@reduxjs/toolkit';

import { IMeetingsState } from '../../types';

export class EditMeetingNotificationFailure {
  static get action() {
    return createAction('meetings/EDIT_NOTIFICATION_FAILURE');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.editMeetingNotificationPending = false;
      return draft;
    };
  }
}
