import * as Sentry from '@sentry/react';
import { SeverityLevel } from '@sentry/react';

import { Exception, LogExceptionOptions, LogInfoOptions, Severity } from './types';

export const logException = (
  error: Exception,
  { source, tags = {}, data, level }: LogExceptionOptions = {},
) => {
  try {
    Sentry.captureException(error, {
      level: level as SeverityLevel,
      extra: data,
      tags: { ...tags, ...(source ? { source } : {}) },
    });
  } catch (err) {
    Sentry.captureException(err, {
      extra: { message: 'Error while logging error' },
    });
  }
};

export const logInfo = ({ message, category, data }: LogInfoOptions) => {
  try {
    Sentry.addBreadcrumb({
      message,
      level: Severity.Info,
      category,
      data,
    });
  } catch (err) {
    Sentry.captureException(err, {
      extra: { message: 'Error while logging info.' },
    });
  }
};

export const createLogExceptionWithOptions =
  (initialOptions: Partial<LogExceptionOptions>) =>
  (error: Exception, options?: LogExceptionOptions) =>
    logException(error, { ...initialOptions, ...options });

export const createLogInfoWithOptions =
  (initialOptions: Partial<LogInfoOptions>) => (options: LogInfoOptions) =>
    logInfo({ ...initialOptions, ...options });

export const logError = createLogExceptionWithOptions({
  level: Severity.Error,
});

export const logFatalException = createLogExceptionWithOptions({
  level: Severity.Fatal,
});

export const logCriticalException = createLogExceptionWithOptions({
  level: Severity.Critical,
});

export const logWarning = createLogExceptionWithOptions({
  level: Severity.Warning,
});
