import { IAlertState } from '@store/alerts/types';
import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { DeleteAlertFailure } from './delete-alert-failure';
import { DeleteAlertSuccess } from './delete-alert-success';

export class DeleteAlert {
  static get action() {
    return createDeferredAction<{ id: number }>('notifications/DELETE_ALERT_PREFERENCES');
  }

  static get reducer() {
    return (draft: IAlertState) => {
      draft.requests.deleteNotificationsPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof DeleteAlert.action>): SagaIterator {
      try {
        DeleteAlert.httpRequest.call(yield call(() => DeleteAlert.httpRequest.generator(payload)));
        yield put(DeleteAlertSuccess.action(payload.id));
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(DeleteAlertFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<null>, { id: number }>(
      ({ id }) => replaceInUrl(MAIN_API.DELETE_NOTIFICATIONS_PREFERENCES, ['id', id]),
      HttpRequestMethod.Delete,
    );
  }
}
