import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { notificationScheduleSelector } from '@store/notifications/selectors';
import { INotificationsState } from '@store/notifications/types';
import { AxiosResponse } from 'axios';
import {
  IPushNotificationSchedulePreference,
  IUpdateNotificationSchedulePreference,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { UpdateNotificationScheduleFailure } from './update-notification-schedule-failure';
import { UpdateNotificationScheduleSuccess } from './update-notification-schedule-success';

export class UpdateNotificationSchedule {
  static get action() {
    return createDeferredAction<IUpdateNotificationSchedulePreference>(
      'notifications/UPDATE_NOTIFICATION_SCHEDULE',
    );
  }

  static get reducer() {
    return (draft: INotificationsState) => {
      draft.requests.updateNotificationSchedulePending = true;
    };
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof UpdateNotificationSchedule.action>): SagaIterator {
      const notificationSchedule: IPushNotificationSchedulePreference = yield select(
        notificationScheduleSelector,
      );

      try {
        UpdateNotificationSchedule.httpRequest.call(
          yield call(() => UpdateNotificationSchedule.httpRequest.generator(payload)),
        );

        yield put(
          UpdateNotificationScheduleSuccess.action({
            id: notificationSchedule.id,
            ...payload,
          }),
        );
        meta?.deferred.resolve();
      } catch (e) {
        yield put(UpdateNotificationScheduleFailure.action());
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<
      AxiosResponse<IPushNotificationSchedulePreference>,
      IUpdateNotificationSchedulePreference
    >(MAIN_API.NOTIFICATIONS_SCHEDULE, HttpRequestMethod.Put);
  }
}
