import { createAction } from '@reduxjs/toolkit';

import { IPurchaseState } from '../types';

export class FinishPurchaseNoFetchProfile {
  static get action() {
    return createAction('purchase/FINISH_PURCHASE_NO_FETCH_PROFILE');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.purchasePending = false;

      return draft;
    };
  }
}
