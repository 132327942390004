import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { IAddBotRequest, IAddBotResponse } from 'lingopractices-models/src/main';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export class CreateUserBot {
  static get action() {
    return createDeferredAction<IAddBotRequest>('user_bots/CREATE_USER_BOT');
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof CreateUserBot.action>): SagaIterator {
      try {
        const { data } = CreateUserBot.httpRequest.call(
          yield call(() => CreateUserBot.httpRequest.generator(payload)),
        );

        const { isSuccessful } = data;

        if (!isSuccessful) {
          throw new Error();
        }

        meta?.deferred.resolve(isSuccessful);
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IAddBotResponse>, IAddBotRequest>(
      MAIN_API.USER_BOTS,
      HttpRequestMethod.Post,
    );
  }
}
