import { BrowserStorage } from '@utils/BrowserStorage';

export class BotAvatarService {
  private readonly avatarKey = 'avatar';

  private browserStorage = new BrowserStorage(this.avatarKey);

  private currentAvatar: string = this.browserStorage.getObject(this.avatarKey);

  public get avatar(): string {
    return this.currentAvatar;
  }

  public initializeOrUpdate(avatar: string) {
    this.browserStorage.setObject(this.avatarKey, avatar);
    this.currentAvatar = avatar;
  }

  public clear() {
    this.browserStorage.clear();
  }
}
