import { createAction } from '@reduxjs/toolkit';
import { ISubscription } from 'lingopractices-models';

import { IPurchaseState } from '../types';

export class FetchAvailablePurchasesSuccess {
  static get action() {
    return createAction<ISubscription[]>('purchase/FETCH_AVAILABLE_PURCHASES_SUCCESS');
  }

  static get reducer() {
    return (
      draft: IPurchaseState,
      { payload }: ReturnType<typeof FetchAvailablePurchasesSuccess.action>,
    ) => {
      draft.requests.fetchAvailablePurchasesPending = false;
      draft.subscriptions = payload;

      return draft;
    };
  }
}
