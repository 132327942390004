import { createAction } from '@reduxjs/toolkit';
import { IAlertState } from '@store/alerts/types';
import { INotificationPreference } from 'lingopractices-models';

export class UpdateAlertSuccess {
  static get action() {
    return createAction<INotificationPreference>('notifications/UPDATE_ALERT_SUCCESS');
  }

  static get reducer() {
    return (draft: IAlertState, { payload }: ReturnType<typeof UpdateAlertSuccess.action>) => {
      draft.requests.updateNotificationsPending = false;

      if (draft.notificationsPreferecnces) {
        const alertIndex = draft.notificationsPreferecnces.findIndex(
          (item) => item.id === payload.id,
        );

        draft.notificationsPreferecnces[alertIndex] = payload;
      } else {
        draft.notificationsPreferecnces = [payload];
      }

      return draft;
    };
  }
}
