import { createAction } from '@reduxjs/toolkit';
import { IGetMeetingSuccessPayload, IMeetingsState } from '@store/meetings/types';
import { uniqBy } from 'lodash';

export class GetMeetingsSuccess {
  static get action() {
    return createAction<IGetMeetingSuccessPayload>('meetings/GET_MEETINGS_SUCCESS');
  }

  static get reducer() {
    return (draft: IMeetingsState, { payload }: ReturnType<typeof GetMeetingsSuccess.action>) => {
      const { data, hasMore, initializedByScroll } = payload;

      draft.requests.getMeetingsPending = false;
      draft.meetings.hasMore = hasMore;

      draft.meetings.meetingList = uniqBy(
        initializedByScroll ? [...draft.meetings.meetingList, ...data] : data,
        'id',
      );

      return draft;
    };
  }
}
