import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';
import { ITopic } from 'lingopractices-models';
import { uniqBy } from 'lodash';

interface IGetTopicsSuccess {
  data: ITopic[];
  hasMore: boolean;
  initializedByScroll: boolean;
}

export class GetTopicsSuccess {
  static get action() {
    return createAction<IGetTopicsSuccess>('topics/GET_TOPICS_SUCCESS');
  }

  static get reducer() {
    return (draft: ITopicsState, { payload }: ReturnType<typeof GetTopicsSuccess.action>) => {
      const { hasMore, data, initializedByScroll } = payload;

      draft.requests.getTopicsPending = false;
      draft.hasMore = hasMore;

      if (initializedByScroll) {
        draft.topics = uniqBy([...draft.topics, ...data], 'id');
      } else {
        draft.topics = uniqBy(data, 'id');
      }

      return draft;
    };
  }
}
