import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse, HttpStatusCode } from 'axios';
import { logError } from 'core/sentry';
import { IMeeting } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { SetSelectedMeeting } from '../set-selected-meeting/set-selected-meeting';

export class GetMeetingById {
  static get action() {
    return createDeferredAction<string>('meetings/GET_MEETING_BY_ID');
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof GetMeetingById.action>): SagaIterator {
      try {
        const { data } = GetMeetingById.httpRequest.call(
          yield call(() => GetMeetingById.httpRequest.generator({ id: payload })),
        );

        if (!data) {
          meta?.deferred.resolve();
          return;
        }

        yield put(SetSelectedMeeting.action(data));

        meta?.deferred.resolve(data);
      } catch (e: any) {
        if (e.response.status === HttpStatusCode.NotFound) {
          meta?.deferred.resolve();
          return;
        }

        meta?.deferred.reject();
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IMeeting>, { id: string }>(
      ({ id }) => replaceInUrl(MAIN_API.GET_MEETING_BY_ID, ['meetingId', id]),
      HttpRequestMethod.Get,
    );
  }
}
