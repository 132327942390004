import { IChatsState } from '@store/chats/types';
import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { getProfileDataSelector } from '@store/profile/selectors';
import { AxiosResponse } from 'axios';
import { FULL_DATE } from 'common/constants';
import { logError } from 'core/sentry';
import dayjs from 'dayjs';
import { IChatMessage, ICreateMeetingChatMessageRequest, IUser } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';

import { CreateMessageFailure } from './create-message-failure';
import { CreateMessageSuccess } from './create-message-success';

export class CreateMessage {
  static get action() {
    return createDeferredAction<ICreateMeetingChatMessageRequest>('chat/CREATE_MESSAGE');
  }

  static get reducer() {
    return (draft: IChatsState) => {
      draft.requests.createMessagePending = true;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof CreateMessage.action>): SagaIterator {
      const { meetingId, text } = payload;

      const user: IUser = yield select(getProfileDataSelector);

      try {
        const { data } = CreateMessage.httpRequest.call(
          yield call(() => CreateMessage.httpRequest.generator({ meetingId, text })),
        );

        const message: IChatMessage = {
          id: data,
          username: user.username,
          userCreatorId: user.id,
          text,
          createdAt: dayjs().format(FULL_DATE),
        };

        yield put(CreateMessageSuccess.action({ message, chatId: meetingId }));
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(CreateMessageFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<number>, ICreateMeetingChatMessageRequest>(
      MAIN_API.CREATE_MESSAGE,
      HttpRequestMethod.Post,
    );
  }
}
