import { createReducer } from '@reduxjs/toolkit';

import { CheckNewMessages } from './features/check-new-messages/check-new-messages';
import { CreateMessage } from './features/create-message/create-message';
import { CreateMessageFailure } from './features/create-message/create-message-failure';
import { CreateMessageSuccess } from './features/create-message/create-message-success';
import { GetChat } from './features/get-chat/get-chat';
import { GetChatFailure } from './features/get-chat/get-chat-failure';
import { GetChatSuccess } from './features/get-chat/get-chat-success';
import { IChatsState } from './types';

const initialState: IChatsState = {
  chats: {},
  requests: {
    getChatMessagesPending: false,
    createMessagePending: false,
  },
};

const reducer = createReducer<IChatsState>(initialState, (builder) =>
  builder
    .addCase(GetChat.action, GetChat.reducer)
    .addCase(GetChatSuccess.action, GetChatSuccess.reducer)
    .addCase(CheckNewMessages.action, CheckNewMessages.reducer)
    .addCase(CreateMessage.action, CreateMessage.reducer)
    .addCase(CreateMessageSuccess.action, CreateMessageSuccess.reducer)
    .addCase(CreateMessageFailure.action, CreateMessageFailure.reducer)
    .addCase(GetChatFailure.action, GetChatFailure.reducer),
);

export default reducer;
