import { EventChannel } from '@redux-saga/core';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { AxiosProgressEvent, AxiosRequestConfig, AxiosResponse } from 'axios';
import noop from 'lodash/noop';
import { END, eventChannel } from 'redux-saga';
import { call, fork, take } from 'redux-saga/effects';

import {
  ICompletedUploadResponse,
  IErrorUploadResponse,
  IEventChannelProgress,
  IFileUploadRequest,
  IProgressUploadResponse,
} from './fileUploaderRequest';

function* uploadRequest<T>(
  request: IFileUploadRequest<T>,
  onProgress: (progressEvent: AxiosProgressEvent) => void,
) {
  const formData = new FormData();

  const { file, url } = request;

  formData.append('file', file);

  const config: AxiosRequestConfig = {
    onUploadProgress: onProgress,
  };

  const httpRequest = httpRequestFactory<AxiosResponse<any>, FormData>(
    url,
    HttpRequestMethod.Post,
    { 'Content-Type': 'multipart/form-data' },
    undefined,
    config,
  );

  try {
    const response = httpRequest.call(yield call(httpRequest.generator, formData));

    if (request.completedCallback) {
      const completedResponse: ICompletedUploadResponse<T> = {
        data: response.data,
        uploadId: request.uploadId as string,
      };

      yield call([request, request.completedCallback], completedResponse);
    }
  } catch (error: any) {
    if (request.errorCallback) {
      const err: IErrorUploadResponse = {
        error,
        uploadId: request.uploadId as string,
      };
      yield call([request, request.errorCallback], err);
    }
  }
}

function createUploader<T>(request: IFileUploadRequest<T>) {
  let emit: any;

  const uploadChannel = eventChannel((emitter) => {
    emit = emitter;
    return noop;
  });

  const onProgress = (progressEvent: AxiosProgressEvent) => {
    const progress = Math.round((progressEvent.loaded / (progressEvent.total ?? 1)) * 100);

    emit({ progressResponse: { progress, uploadId: request.uploadId } });
    if (progress === 100) {
      emit(END);
    }
  };

  const uploadPromise = uploadRequest(request, onProgress);

  return [uploadPromise, uploadChannel];
}

export function* uploadFileSaga<T>(request: IFileUploadRequest<T>) {
  function* watchOnProgress(uploadChannel: EventChannel<IEventChannelProgress>) {
    while (true) {
      const data: IEventChannelProgress = yield take(uploadChannel);

      if (request.progressCallback) {
        const response: IProgressUploadResponse = {
          progress: data.progressResponse.progress,
          uploadId: data.progressResponse.uploadId,
        };
        yield call([request, request.progressCallback], response);
      }
    }
  }

  const [uploadPromise, uploadChannel] = createUploader(request);

  yield fork(watchOnProgress, uploadChannel as EventChannel<IEventChannelProgress>);
  yield call(() => uploadPromise);
}
