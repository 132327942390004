import { IChatsState } from '@store/chats/types';
import { createDeferredAction } from '@store/common/actions';
import { IChatMessage } from 'lingopractices-models';
import { uniqBy } from 'lodash';

interface ICreateMessageSuccessPayload {
  chatId: number;
  message: IChatMessage;
}

export class CreateMessageSuccess {
  static get action() {
    return createDeferredAction<ICreateMessageSuccessPayload>('chat/CREATE_MESSAGE_SUCCESS');
  }

  static get reducer() {
    return (draft: IChatsState, { payload }: ReturnType<typeof CreateMessageSuccess.action>) => {
      const { chatId, message } = payload;

      draft.requests.createMessagePending = false;

      const { messages } = draft.chats[chatId];

      draft.chats[chatId].messages = uniqBy([...messages, message], 'id');
    };
  }
}
