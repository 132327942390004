import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { CreateAlert } from './features/create-alert/create-alert';
import { DeleteAlert } from './features/delete-alert/delete-alert';
import { GetAlerts } from './features/get-alerts/get-alerts';
import { GetAlertState } from './features/store-alert/get-alert';
import { SaveAlertState } from './features/store-alert/save-alert';
import { UpdateAlert } from './features/update-alert/update-alert';

export function* alertsSagas() {
  yield all([
    takeEvery(GetAlerts.action, GetAlerts.saga),
    takeLatest(CreateAlert.action, CreateAlert.saga),
    takeLatest(UpdateAlert.action, UpdateAlert.saga),
    takeEvery(GetAlertState.action, GetAlertState.saga),
    takeEvery(SaveAlertState.action, SaveAlertState.saga),
    takeEvery(DeleteAlert.action, DeleteAlert.saga),
  ]);
}
