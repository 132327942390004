import { SessionStorage } from '@utils/session-storage';
import { ILanguage } from 'lingopractices-models';

export type ILanguagesType = Record<string, ILanguage[]>;

export class LanguageService {
  private readonly languagesKey = 'languages';

  private browserStorage = new SessionStorage(this.languagesKey);

  private currentLanguages: ILanguagesType = this.browserStorage.getObject<ILanguagesType>(
    this.languagesKey,
  );

  public get languages(): ILanguagesType {
    return this.currentLanguages;
  }

  public initializeOrUpdate(languages: ILanguagesType) {
    this.browserStorage.setObject<ILanguagesType>(this.languagesKey, languages);
    this.currentLanguages = languages;
  }

  public clear() {
    this.browserStorage.clear();
  }
}
