import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';
import { ITopic } from 'lingopractices-models';

export class GetTopicsByTagIdSuccess {
  static get action() {
    return createAction<ITopic[]>('topics/GET_TOPICS_BY_TAG_ID_SUCCESS');
  }

  static get reducer() {
    return (
      draft: ITopicsState,
      { payload }: ReturnType<typeof GetTopicsByTagIdSuccess.action>,
    ) => {
      draft.topicsByTagId = payload || [];

      return draft;
    };
  }
}
