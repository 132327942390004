import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { DeleteUserBotSuccess } from '@store/user-bots/features/delete-user-bot/delete-user-bot-success';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { IDeleteBotCommand } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export class DeleteUserBot {
  static get action() {
    return createDeferredAction<IDeleteBotCommand>('user_bots/DELETE_USER_BOT');
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof DeleteUserBot.action>): SagaIterator {
      try {
        DeleteUserBot.httpRequest.call(
          yield call(() => DeleteUserBot.httpRequest.generator(payload)),
        );
        yield put(DeleteUserBotSuccess.action(payload.nickname));
        meta?.deferred.resolve();
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<boolean>, IDeleteBotCommand>(
      MAIN_API.USER_BOTS,
      HttpRequestMethod.Delete,
    );
  }
}
