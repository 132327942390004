import { createAction } from '@reduxjs/toolkit';
import { ICreateYookassaPaymentResponse } from 'lingopractices-models';

import { IPurchaseState } from '../types';

export class GetPaymentLinkYookassaSuccess {
  static get action() {
    return createAction<ICreateYookassaPaymentResponse>(
      'purchase/GET_PAYMENT_LINK_YOOKASSA_SUCCESS',
    );
  }

  static get reducer() {
    return (
      draft: IPurchaseState,
      { payload }: ReturnType<typeof GetPaymentLinkYookassaSuccess.action>,
    ) => {
      draft.requests.getPaymentLinkPending = false;
      draft.paymentLinks.yookassa = payload;

      return draft;
    };
  }
}
