import { GetTags } from '@store/topics/features/get-tags/get-tags';
import { GetTopicsByTagId } from '@store/topics/features/get-topics-by-tagId/get-topics-by-tagId';
import { all, takeEvery, takeLatest } from 'redux-saga/effects';

import { CreateTopic } from './features/create-topic/create-topic';
import { EditTopic } from './features/edit-topic/edit-topic';
import { GetTopicById } from './features/get-topic-by-id/get-topic-by-id';
import { GetTopics } from './features/get-topics/get-topics';

export function* topicSagas() {
  yield all([
    takeLatest(GetTopics.action, GetTopics.saga),
    takeEvery(CreateTopic.action, CreateTopic.saga),
    takeEvery(EditTopic.action, EditTopic.saga),
    takeEvery(GetTopicById.action, GetTopicById.saga),
    takeLatest(GetTags.action, GetTags.saga),
    takeEvery(GetTopicsByTagId.action, GetTopicsByTagId.saga),
  ]);
}
