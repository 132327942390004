import { createAction } from '@reduxjs/toolkit';
import { IMeetingsState } from '@store/meetings/types';

export class DeleteMeetingFromStore {
  static get action() {
    return createAction<number>('meetings/DELETE_MEETING_FROM_STORE');
  }

  static get reducer() {
    return (
      draft: IMeetingsState,
      { payload }: ReturnType<typeof DeleteMeetingFromStore.action>,
    ) => {
      draft.meetings.meetingList = draft.meetings.meetingList.filter(
        (meeting) => meeting.id !== payload,
      );

      return draft;
    };
  }
}
