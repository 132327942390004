import { createAction } from '@reduxjs/toolkit';
import {
  getCurrentLocationAction,
  getCurrentLocationSuccessAction,
  getCurrentLocationFailureAction,
  getTimeZoneAction,
  updateProfileAction,
  getTimeZoneFailureAction,
  getTimeZoneSuccessAction,
} from '@store/profile/actions';
import { getProfileDataSelector } from '@store/profile/selectors';
import { IUser } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { put, race, select, take } from 'redux-saga/effects';

export class ResolveUserLocation {
  static get action() {
    return createAction('init/RESOLVE_USER_LOCATION');
  }

  static get saga() {
    return function* (): SagaIterator {
      const user: IUser = yield select(getProfileDataSelector);

      if (!user || user.timeZoneId && user.countryCode) {
        return;
      }

      yield put(getCurrentLocationAction(null));

      const resolveLocationTask: {
        success: ReturnType<typeof getCurrentLocationSuccessAction>;
        failure: any;
      } = yield race({
        success: take(getCurrentLocationSuccessAction),
        failure: take(getCurrentLocationFailureAction),
      });

      if (resolveLocationTask?.success?.payload) {
        const {
          latitude,
          longitude,
          country: countryName,
          country_code: countryCode,
          city
        } = resolveLocationTask.success.payload;

        const getTimezonePayload = {
          lat: latitude,
          lng: longitude,
        };

        yield put(getTimeZoneAction(getTimezonePayload));

        const resolveTimeZoneTask: {
          success: ReturnType<typeof getTimeZoneSuccessAction>;
          failure: any;
        } = yield race({
          success: take(getTimeZoneSuccessAction),
          failure: take(getTimeZoneFailureAction),
        });

        if (resolveTimeZoneTask?.success?.payload) {
          const timeZoneId = resolveTimeZoneTask?.success?.payload;

          yield put(
            updateProfileAction({
              ...user,
              practiceLanguageId: user.practiceLanguage?.id,
              interfaceLanguageId: user.interfaceLanguage.id,
              countryName,
              countryCode,
              city,
              timeZoneId,
            }),
          );
        }
      }
    };
  }
}
