import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { LogInProviderType } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, cancel, cancelled, delay, fork, put, take } from 'redux-saga/effects';

import { GetProfile } from '../get-profile/get-profile';
import { GetProfileSuccess } from '../get-profile/get-profile-success';

import { CancelCheckSignVisit } from './cancel-check-sign-visit';

export interface ICheckSignVisit {
  requestId: string;
  authType: LogInProviderType;
}

export class CheckSignVisit {
  static get action() {
    return createDeferredAction<ICheckSignVisit>('profile/CHECK_GOOGLE_VISIT');
  }

  // if status 200 continue call CHECK GOOGLE_VISIT path, when 404 - get user and check its permissions
  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof CheckSignVisit.action>): SagaIterator {
      const { requestId, authType } = payload;

      function* checkVisitFunction(): SagaIterator {
        try {
          while (true) {
            CheckSignVisit.httpRequest.call(
              yield call(() => CheckSignVisit.httpRequest.generator({ requestId })),
            );

            if (yield cancelled()) {
              break;
            }

            yield delay(2000);
          }
        } catch (e: any) {
          try {
            const { data } = GetProfile.httpRequest.call(
              yield call(() => GetProfile.httpRequest.generator()),
            );

            if (
              authType === LogInProviderType.Google &&
              data?.userGoogleInfo?.hasCalendarPermission
            ) {
              yield put(GetProfileSuccess.action(data));

              meta?.deferred.resolve();
            } else if (authType === LogInProviderType.Zoom && data.userZoomInfo?.authorized) {
              yield put(GetProfileSuccess.action(data));

              meta?.deferred.resolve();
            } else {
              meta?.deferred.reject(e);
            }
          } catch (err: any) {
            meta?.deferred.reject(err);
            logError(err);
          }
        }
      }

      const checkVisitGoogleTask = yield fork(checkVisitFunction);

      yield take(CancelCheckSignVisit.action);

      yield cancel(checkVisitGoogleTask);
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, { requestId: string }>(
      ({ requestId }) => replaceInUrl(MAIN_API.CHECK_GOOGLE_VISIT, ['requestId', requestId]),
      HttpRequestMethod.Get,
    );
  }
}
