import { createAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { PlusFeaturesRepository } from 'services/plus-features-service';

import { SetTopicGeneratorUses } from './set-topic-generator-uses';
import { SetTopicGeneratorUsesLocal } from './set-topic-generator-uses-local';

export class RefreshTopicGeneratorUses {
  static get action() {
    return createAction('openAI/REFRESH_TOPIC_GENERATOR_USES');
  }

  static get saga() {
    // eslint-disable-next-line require-yield
    return function* (): SagaIterator {
      const today = dayjs();
      const lastTGUsesRefreshTimestamp: number = yield call(
        PlusFeaturesRepository.getLastTGUsesRefreshedTimestamp,
      );

      if (!lastTGUsesRefreshTimestamp || today.isAfter(dayjs(lastTGUsesRefreshTimestamp), 'day')) {
        PlusFeaturesRepository.setLastTGUsesRefreshedTimestampToNow();
        yield put(SetTopicGeneratorUses.action(1));
      } else {
        const currentTGUses: number = yield call(PlusFeaturesRepository.getTGUsesLeft);
        yield put(SetTopicGeneratorUsesLocal.action(currentTGUses));
      }
    };
  }
}
