import { createAction } from '@reduxjs/toolkit';
import { INotificationsState } from '@store/notifications/types';
import { IPushNotificationSchedulePreference } from 'lingopractices-models';

export class GetNotificationScheduleSuccess {
  static get action() {
    return createAction<IPushNotificationSchedulePreference>(
      'notifications/GET_NOTIFICATION_SCHEDULE_SUCCESS',
    );
  }

  static get reducer() {
    return (
      draft: INotificationsState,
      { payload }: ReturnType<typeof GetNotificationScheduleSuccess.action>,
    ) => {
      draft.notificationSchedule = payload;
      draft.requests.getNotificationSchedulePending = false;

      return draft;
    };
  }
}
