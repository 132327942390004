import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { ABSTRACT_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetCurrentLocationFailure } from './get-current-location-failure';
import { GetCurrentLocationSuccess } from './get-current-location-success';
import { IAbstractResponse } from './types';

export class GetCurrentLocation {
  static get action() {
    return createDeferredAction('profile/GET_CURRENT_LOCATION');
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetCurrentLocation.action>): SagaIterator {
      try {
        const { data } = GetCurrentLocation.httpRequest.call(
          yield call(() => GetCurrentLocation.httpRequest.generator()),
        );

        const {
          city,
          country: countryName,
          country_code: countryCode,
          longitude,
          latitude,
        } = data;

        meta?.deferred?.resolve({
          city,
          countryName,
          countryCode,
          longitude,
          latitude,
        });

        yield put(GetCurrentLocationSuccess.action(data));
      } catch (e: any) {
        meta?.deferred?.reject(e);
        logError(e);
        yield put(GetCurrentLocationFailure.action());
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IAbstractResponse>, undefined>(
      ABSTRACT_API,
      HttpRequestMethod.Get,
      undefined,
      true,
    );
  }
}
