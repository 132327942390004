import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetPaymentLinkTelegramFailure } from '@store/purchase/get-payment-link-telegram/get-payment-link-telegram-failure';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ICreateYookassaPaymentRequest } from 'lingopractices-models';
import {
  ICreateTelegramPaymentRequest,
  ICreateTelegramPaymentResponse,
} from 'lingopractices-models/src/main';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { IPurchaseState } from '../types';

import { GetPaymentLinkTelegramSuccess } from './get-payment-link-telegram-success';

export class GetPaymentLinkTelegram {
  static get action() {
    return createDeferredAction<ICreateYookassaPaymentRequest>(
      'purchase/GET_PAYMENT_LINK_TELEGRAM',
    );
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.getPaymentLinkPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof GetPaymentLinkTelegram.action>): SagaIterator {
      try {
        const { data } = GetPaymentLinkTelegram.httpRequest.call(
          yield call(() => GetPaymentLinkTelegram.httpRequest.generator(payload)),
        );

        if (data.link && data.paymentId) {
          yield put(GetPaymentLinkTelegramSuccess.action());
          meta?.deferred.resolve(data);
        } else {
          meta?.deferred.reject();
          yield put(GetPaymentLinkTelegramFailure.action());
        }
      } catch (e: any) {
        meta?.deferred.reject(e);
        yield put(GetPaymentLinkTelegramFailure.action());
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<
      AxiosResponse<ICreateTelegramPaymentResponse>,
      ICreateTelegramPaymentRequest
    >(MAIN_API.GET_PAYMENT_LINK_TELEGRAM, HttpRequestMethod.Post);
  }
}
