import { BrowserStorage } from '@utils/BrowserStorage';

export class CountShowingAdultBannerService {
  private readonly key = 'countShowingAdultBanner';

  private browserStorage = new BrowserStorage(this.key);

  private currentCount: number = this.browserStorage.getObject(this.key);

  public get count(): number {
    return this.currentCount;
  }

  public initializeOrUpdate(count: number) {
    this.browserStorage.setObject(this.key, count);
    this.currentCount = count;
  }

  public clear() {
    this.browserStorage.clear();
  }
}
