import { HOUR_MINUTE, WEEK_DAYS } from 'common/constants';
import dayjs, { Dayjs } from 'dayjs';
import i18next from 'i18next';
import { IMeeting, NotificationDays } from 'lingopractices-models';
import { IWeekDay, TWeekDayValue } from 'screens/account/AlertsSchedule/AlertsSchedule.types';

export const getMinTimeOfDay = (date: Dayjs) =>
  date.set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0);

export const getLocaleTimeFormat = (date: string, lang: string) =>
  dayjs(date).format(lang === 'ru' || lang === 'be' || lang === 'uk' ? 'H:mm' : 'h:mm A');

export const getLocaleTimeFormatWithOffset = (date: string, lang: string) =>
  dayjs(date).format(lang === 'ru' || lang === 'be' || lang === 'uk' ? 'H:mm (z)' : 'h:mm A (z)');

export const getMaxTimeOfDay = (date: Dayjs) =>
  dayjs(date).set('hour', 23).set('minute', 59).set('second', 0).set('millisecond', 0);

export const getEndOfThisWeek = () => dayjs().endOf('weeks').add(1, 'day');

export const getEndOfNextWeek = () =>
  dayjs().endOf('weeks').add(1, 'day').endOf('weeks').add(1, 'day');

export const mergeDateAndTime = (date: Dayjs, time: Dayjs) =>
  date
    .set('hour', time.hour())
    .set('minute', time.minute())
    .set('second', time.second())
    .set('milliseconds', 0);

export const getTomorrowDate = (date: Dayjs) => date.add(1, 'day');

export const sortGrowingDates = (dates: IMeeting[]) =>
  dates.sort((a, b) => dayjs(a.meetingDate).unix() - dayjs(b.meetingDate).unix());

export const getAvailableTimes = (date: Dayjs) => {
  const availableTimes = [];
  const maxTime = getMaxTimeOfDay(date as Dayjs);
  let minTime;

  if (date.isToday()) {
    const now = dayjs();
    minTime = now
      .set('milliseconds', 0)
      .set('seconds', 0)
      .set('minutes', now.minute() < 30 ? 30 : 0)
      .set('hours', now.minute() > 30 ? now.hour() + 1 : now.hour());
  } else {
    minTime = date.set('minutes', 0).set('second', 0).set('hours', 0);
  }

  while (minTime <= maxTime) {
    availableTimes.push(minTime);
    minTime = minTime.set('minute', minTime.minute() + 30);
  }

  return availableTimes;
};

export const getAvailableTimesSchedule = (
  timeFrom: Dayjs,
  timeTo: Dayjs,
  timeFromBool: boolean,
  timeToBool: boolean,
) => {
  const availableTimes: Dayjs[] = [];
  if (timeFromBool) {
    let minTime = dayjs(timeFrom).set('minutes', 0).set('hours', 0);
    while (minTime < timeTo) {
      availableTimes.push(minTime);
      minTime = minTime.set('minute', minTime.minute() + 30);
    }
  } else if (timeToBool) {
    let timeFromVar = dayjs(timeFrom).set('minute', timeFrom.minute() + 30);
    const maxTime = dayjs().set('hours', 23).set('minutes', 30);
    while (timeFromVar <= maxTime) {
      availableTimes.push(timeFromVar);
      timeFromVar = timeFromVar.set('minute', timeFromVar.minute() + 30);
    }
  }
  return availableTimes;
};

export const getNextMonthLastDate = (date: Dayjs) => {
  const lastMonth = 11;
  const currentMonth = date.month();
  const currentYear = date.year();

  return getMaxTimeOfDay(
    date
      .set('year', currentMonth === lastMonth ? currentYear + 1 : currentYear)
      .set('month', currentMonth === lastMonth ? 0 : currentMonth + 1),
  ).endOf('month');
};

export const getSevenNextDates = (startDate: Dayjs): Dayjs[] => {
  const result: Dayjs[] = [];

  for (let i = 0; i < WEEK_DAYS; i += 1) {
    result.push(startDate.add(i, 'day'));
  }

  return result;
};

export const sortDescendingDates = (dates: IMeeting[]) =>
  dates.sort((a, b) => dayjs(b.meetingDate).unix() - dayjs(a.meetingDate).unix());

export const createDateWithTime = (time: string) => {
  const timeArray = time.split(':');

  return dayjs()
    .set('hour', +timeArray[0])
    .set('minute', +timeArray[1])
    .set('second', 0)
    .set('milliseconds', 0);
};

export const formatTime = (date: Dayjs) => `${date.format(HOUR_MINUTE)}:00`;

export const daysOfWeek: () => IWeekDay[] = () => {
  const firstDayOfWeek = +dayjs().locale(i18next.language).startOf('week').format('d');

  return new Array(7).fill(null).map((item, index) => {
    const dayNum = (index + firstDayOfWeek) % 7;

    const dayName = dayjs().locale(i18next.language).day(dayNum).format('dddd');
    const dayValue = dayjs().locale('en').day(dayNum).format('dddd') as TWeekDayValue;
    return {
      id: dayNum,
      name: dayName.charAt(0).toUpperCase() + dayName.slice(1),
      value: NotificationDays[dayValue],
    };
  });
};
