import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ISubscription } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { IPurchaseState } from '../types';

import { FetchAvailablePurchasesFailure } from './fetch-available-purchases-failure';
import { FetchAvailablePurchasesSuccess } from './fetch-available-purchases-success';

export class FetchAvailablePurchases {
  static get action() {
    return createDeferredAction('purchase/FETCH_AVAILABLE_PURCHASES');
  }

  static get reducer() {
    return (draft: IPurchaseState) => {
      draft.requests.fetchAvailablePurchasesPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof FetchAvailablePurchases.action>): SagaIterator {
      try {
        const { data } = FetchAvailablePurchases.httpRequest.call(
          yield call(() => FetchAvailablePurchases.httpRequest.generator()),
        );

        const sortedSub = data.sort((a, b) => {
          if (!a.prices?.USD || !b.prices?.USD) return 0;
          return b.prices.USD - a.prices.USD;
        });

        yield put(FetchAvailablePurchasesSuccess.action(sortedSub));
        meta?.deferred.resolve();
      } catch (e: any) {
        yield put(FetchAvailablePurchasesFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ISubscription[]>, null>(
      MAIN_API.FETCH_AVAILABLE_SUBSCRIPTIONS,
      HttpRequestMethod.Get,
    );
  }
}
