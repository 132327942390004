import { createReducer } from '@reduxjs/toolkit';
import { getStartParamFromUrl, getTokenFromUrl } from '@utils/url';

import { IAuthState } from './auth-state';
import { AuthInit } from './features/init-auth/init-auth';

const securityToken =
  import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION'
    ? window.Telegram.WebApp.initData
    : window.Telegram.WebApp.initData || import.meta.env.VITE_TG_TOKEN;

const initialState: IAuthState = {
  isAuthenticated: false,
  securityToken: getTokenFromUrl() || securityToken,
  startParamFromURl: getStartParamFromUrl(),
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(AuthInit.action, AuthInit.reducer);
});

export default reducer;
