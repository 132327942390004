import { RootState } from '@store/store';

export const notificationScheduleSelector = (state: RootState) =>
  state.notifications.notificationSchedule;

export const getNotificationSchedulePendingSelector = (state: RootState) =>
  state.notifications.requests.getNotificationSchedulePending;

export const updateNotificationSchedulePendingSelector = (state: RootState) =>
  state.notifications.requests.updateNotificationSchedulePending;
