import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { GOOGLE_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import dayjs from 'dayjs';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetTimeZoneFailure } from './get-time-zone_failure';
import { GetTimeZoneSuccess } from './get-time-zone_success';
import { TimeZoneResponseType } from './types';

export class GetTimeZone {
  static get action() {
    return createDeferredAction<Pick<google.maps.LatLngAltitude, 'lat' | 'lng'>>(
      'profile/GET_TIME_ZONE',
    );
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof GetTimeZone.action>): SagaIterator {
      const { lat, lng } = payload;
      try {
        const { data } = GetTimeZone.httpRequest.call(
          yield call(() =>
            GetTimeZone.httpRequest.generator({
              lat,
              lng,
              timeStamp: dayjs().unix(),
              apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
            }),
          ),
        );

        const { timeZoneId } = data;

        meta?.deferred?.resolve(timeZoneId);

        yield put(GetTimeZoneSuccess.action(timeZoneId));
      } catch (e: any) {
        yield put(GetTimeZoneFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<
      AxiosResponse<TimeZoneResponseType>,
      { lat: number; lng: number; timeStamp: number; apiKey: string }
    >(
      ({ lat, lng, timeStamp, apiKey }) =>
        new URL(
          replaceInUrl(
            GOOGLE_API.GET_TIMEZONE,
            ['lat', lat],
            ['lng', lng],
            ['timeStamp', timeStamp],
            ['apiKey', apiKey],
          ),
        ).href,
      HttpRequestMethod.Get,
      undefined,
      true,
    );
  }
}
