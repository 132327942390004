import { createDeferredAction } from '@store/common/actions';
import { ITopicsState } from '@store/topics/types';
import { ITopic } from 'lingopractices-models';

export class CreateTopicSuccess {
  static get action() {
    return createDeferredAction<ITopic>('topics/CREATE_TOPIC_SUCCESS');
  }

  static get reducer() {
    return (draft: ITopicsState, { payload }: ReturnType<typeof CreateTopicSuccess.action>) => {
      draft.requests.createTopicPending = false;

      draft.topics = [payload, ...draft.topics];

      return draft;
    };
  }
}
