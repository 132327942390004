import { createAction } from '@reduxjs/toolkit';
import { IMeetingsState } from '@store/meetings/types';
import { sortGrowingDates } from '@utils/date-utils';
import { IMeeting } from 'lingopractices-models';

export class JoinMeetingSuccess {
  static get action() {
    return createAction<IMeeting>('meetings/JOIN_MEETING_SUCCESS');
  }

  static get reducer() {
    return (draft: IMeetingsState, { payload }: ReturnType<typeof JoinMeetingSuccess.action>) => {
      draft.requests.joinMeetingPending = false;

      draft.myMeetings.meetingList = sortGrowingDates([...draft.myMeetings.meetingList, payload]);

      const allMeetingIdx = draft.meetings.meetingList.findIndex(
        (meeting) => meeting.id === payload.id,
      );

      draft.meetings.meetingList[allMeetingIdx] = payload;

      return draft;
    };
  }
}
