import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { IGenerateQuestionsRequest } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export interface IGenerateQuestionsResponse {
  isSuccess: boolean;
  questions: string[] | null;
}

export class GetTopicQuestions {
  static get action() {
    return createDeferredAction<IGenerateQuestionsRequest>('openAI/GET_TOPIC_QUESTIONS');
  }

  static get saga() {
    return function* ({
      payload,
      meta,
    }: ReturnType<typeof GetTopicQuestions.action>): SagaIterator {
      try {
        const { data } = GetTopicQuestions.httpRequest.call(
          yield call(() => GetTopicQuestions.httpRequest.generator(payload)),
        );

        meta?.deferred.resolve(data);
      } catch (e) {
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IGenerateQuestionsResponse>, IGenerateQuestionsRequest>(
      MAIN_API.OPEN_AI_TOPIC_QUESTIONS,
      HttpRequestMethod.Post,
    );
  }
}
