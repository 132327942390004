import { createAction } from '@reduxjs/toolkit';
import { IMeetingsState } from '@store/meetings/types';

export class SwitchFilters {
  static get action() {
    return createAction<boolean>('SWITCH_MAIN_FILTERS');
  }

  static get reducer() {
    return (draft: IMeetingsState, { payload }: ReturnType<typeof SwitchFilters.action>) => {
      draft.filters.isMyMeetingsOpened = payload;
    };
  }
}
