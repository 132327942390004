import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { languagesSelector } from '@store/languages/selectors';
import { IMeetingsState } from '@store/meetings/types';
import { GetSpeeches } from '@store/profile/features/get-speeches/get-speeches';
import { PlatformAuth } from '@store/profile/features/platform-auth/platform-auth';
import {
  getInterfaceLanguageSelector,
  getProfileDataSelector,
  speechesSelector,
} from '@store/profile/selectors';
import { addPendingRequest } from '@utils/cancel-request';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { logError, logInfo } from 'core/sentry';
import {
  CreateMeetingResult,
  ICreateMeetingRequest,
  ICreateMeetingResponse,
  ILanguage,
  IMeeting,
  IUser,
  LogInProviderType,
  MeetingType,
} from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put, select, spawn } from 'redux-saga/effects';

import { CreateMeetingFailure } from './create-meeting-failure';
import { CreateMeetingSuccess } from './create-meeting-success';

export class CreateMeeting {
  static get action() {
    return createDeferredAction<ICreateMeetingRequest>('meetings/CREATE_MEETING');
  }

  static get reducer() {
    return (draft: IMeetingsState) => {
      draft.requests.createMeetingPending = true;
      return draft;
    };
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof CreateMeeting.action>): SagaIterator {
      const {
        id: userId,
        gender,
        countryName: country,
        countryCode,
        aboutMe,
        registeredAt: createdAt,
      } = yield select(getProfileDataSelector);

      const languages: ILanguage[] = yield select(languagesSelector);
      const locale: ILanguage = yield select(getInterfaceLanguageSelector);
      const speeches: Record<string, string[]> = yield select(speechesSelector);

      if (!speeches[locale.id]) {
        yield spawn(GetSpeeches.saga);
      }

      try {
        const response = CreateMeeting.httpRequest.call(
          yield call(() =>
            CreateMeeting.httpRequest.generator(payload, (token: CancelTokenSource) =>
              addPendingRequest(userId, token),
            ),
          ),
        );

        if (!response) {
          return;
        }

        const {
          data: { id, createMeetingResult },
        } = response;
        switch (createMeetingResult) {
          case CreateMeetingResult.Success: {
            const userCreator: IUser = yield select(getProfileDataSelector);

            const isOnlineMeeting = payload.type !== MeetingType.Offline;

            const meetingLanguage = languages.find(
              (language) => language.id === payload.languageId,
            ) as ILanguage;

            const createdMeeting: IMeeting = {
              id: id as number,
              meetingDate: payload.meetingAt,
              language: meetingLanguage,
              languageLevel: payload.languageLevel,
              maxParticipantsCount: payload.peopleNumber,
              participants: [
                {
                  userId,
                  gender,
                  country,
                  username: userCreator.username,
                  countryCode,
                  aboutMe,
                  createdAt,
                  blocked: false,
                },
              ],
              topicName: payload.topicName,
              topicDescription: payload.topicDescription,
              userCreator,
              address: !isOnlineMeeting
                ? {
                    ...payload.address,
                  }
                : undefined,
              comment: payload.comment || undefined,
              type: payload.type,
              notificationsEnabled: true,
              preferVideo: payload.preferVideo,
              duration: payload.duration,
              customMeetingInfo: payload.customMeetingInfo,
            };

            yield put(CreateMeetingSuccess.action(createdMeeting));
            meta?.deferred.resolve();

            break;
          }
          case CreateMeetingResult.TokenHasBeenExpiredOrRevoked: {
            logInfo({ message: 'createMeetingResult: TokenHasBeenExpiredOrRevoked' });
            const logInProvider =
              payload.type === MeetingType.GoogleMeet
                ? LogInProviderType.Google
                : LogInProviderType.Zoom;

            try {
              const { data } = PlatformAuth.httpRequest.call(
                yield call(() => PlatformAuth.httpRequest.generator(logInProvider)),
              );

              const { logInUrl } = data;

              if (logInUrl) {
                meta?.deferred.reject({ e: createMeetingResult, url: logInUrl });
                yield put(CreateMeetingFailure.action());
              }
            } catch (e) {
              meta?.deferred.reject({ e: createMeetingResult });
              yield put(CreateMeetingFailure.action());
            }
            break;
          }
          default: {
            meta?.deferred.reject({ e: createMeetingResult });
            yield put(CreateMeetingFailure.action());
            logInfo({ message: `createMeetingResult: ${createMeetingResult}` });

            break;
          }
        }
      } catch (e: any) {
        yield put(CreateMeetingFailure.action());
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ICreateMeetingResponse>, ICreateMeetingRequest>(
      MAIN_API.CREATE_MEETING,
      HttpRequestMethod.Post,
    );
  }
}
