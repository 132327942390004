import { createReducer } from '@reduxjs/toolkit';
import { GetBanners } from '@store/banners/features/get-banners/get-banners';
import { GetBannersFailure } from '@store/banners/features/get-banners/get-banners-failure';
import { GetBannersSuccess } from '@store/banners/features/get-banners/get-banners-success';
import { MarkBannerWatchedSuccess } from '@store/banners/features/mark-banner-watched/mark-banner-watched-success';

import { IBannersState } from './types';

export const bannersState: IBannersState = {
  banners: [],
  requests: {
    getBannersPending: false,
  },
};

const reducer = createReducer(bannersState, (builder) =>
  builder
    .addCase(GetBanners.action, GetBanners.reducer)
    .addCase(GetBannersSuccess.action, GetBannersSuccess.reducer)
    .addCase(GetBannersFailure.action, GetBannersFailure.reducer)
    .addCase(MarkBannerWatchedSuccess.action, MarkBannerWatchedSuccess.reducer),
);

export default reducer;
