import { createReducer } from '@reduxjs/toolkit';
import { GetMyStatistics } from '@store/profile/features/get-statistics/get-my-statistics';
import { GetMyStatisticsFailure } from '@store/profile/features/get-statistics/get-my-statistics-failure';
import { GetMyStatisticsSuccess } from '@store/profile/features/get-statistics/get-my-statistics-success';

import { ChangeTheme } from './features/change-theme/change-theme';
import { GetFaq } from './features/get-faq/get-faq';
import { GetFaqSuccess } from './features/get-faq/get-faq-success';
import { GetProfile } from './features/get-profile/get-profile';
import { GetProfileSuccess } from './features/get-profile/get-profile-success';
import { GetSpeechesSuccess } from './features/get-speeches/get-speeches-success';
import { UpdateProfile } from './features/update-profile/update-profile';
import { UpdateProfileFailure } from './features/update-profile/update-profile-failure';
import { UpdateProfileSuccess } from './features/update-profile/update-profile-success';
import { IProfileState } from './types';

const initialState: IProfileState = {
  profileInfo: undefined,
  theme: undefined,
  speeches: {},
  faqs: {},
  statistics: null,
  requests: {
    getProfileInfoPending: false,
    updateProfilePending: false,
    getFaqsPending: false,
    getMyStatisticsPending: false,
  },
};

const reducer = createReducer<IProfileState>(initialState, (builder) => {
  builder
    .addCase(GetProfile.action, GetProfile.reducer)
    .addCase(GetProfileSuccess.action, GetProfileSuccess.reducer)
    .addCase(UpdateProfile.action, UpdateProfile.reducer)
    .addCase(UpdateProfileSuccess.action, UpdateProfileSuccess.reducer)
    .addCase(UpdateProfileFailure.action, UpdateProfileFailure.reducer)
    .addCase(ChangeTheme.action, ChangeTheme.reducer)
    .addCase(GetSpeechesSuccess.action, GetSpeechesSuccess.reducer)
    .addCase(GetFaqSuccess.action, GetFaqSuccess.reducer)
    .addCase(GetFaq.action, GetFaq.reducer)
    .addCase(GetMyStatistics.action, GetMyStatistics.reducer)
    .addCase(GetMyStatisticsSuccess.action, GetMyStatisticsSuccess.reducer)
    .addCase(GetMyStatisticsFailure.action, GetMyStatisticsFailure.reducer);
});

export default reducer;
