import { createAction } from '@reduxjs/toolkit';
import { IUserBotsState } from '@store/user-bots/types';
import { ITelegramBotInfo } from 'lingopractices-models';

export class SetSelectedUserBot {
  static get action() {
    return createAction<ITelegramBotInfo | null>('user_bots/SET_SELECTED_BOTS');
  }

  static get reducer() {
    return (draft: IUserBotsState, { payload }: ReturnType<typeof SetSelectedUserBot.action>) => {
      draft.selectedUserBot = payload;

      return draft;
    };
  }
}
