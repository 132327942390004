import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { GOOGLE_API } from '@store/common/path';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';
import { ICoords } from 'screens/types';

export class GetAddressByLatLng {
  static get action() {
    return createDeferredAction<ICoords>('profile/GET_ADDRESS_BY_LAT_LNG');
  }

  static get saga() {
    return function* ({
      meta,
      payload,
    }: ReturnType<typeof GetAddressByLatLng.action>): SagaIterator {
      const { lat, lng } = payload;

      try {
        const { data } = GetAddressByLatLng.httpRequest.call(
          yield call(() =>
            GetAddressByLatLng.httpRequest.generator({
              lat,
              lng,
              apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
            }),
          ),
        );

        meta?.deferred.resolve(data.results);
      } catch (e: any) {
        meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<
      AxiosResponse<google.maps.GeocoderResponse>,
      { lat: number; lng: number; apiKey: string }
    >(
      ({ lat, lng, apiKey }) =>
        replaceInUrl(
          GOOGLE_API.GET_ADDRESS_BY_LAT_LNG,
          ['lat', lat],
          ['lng', lng],
          ['apiKey', apiKey],
        ),
      HttpRequestMethod.Get,
      undefined,
      true,
    );
  }
}
