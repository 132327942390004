import { createAction } from '@reduxjs/toolkit';
import { openedMyMeetingsSelector } from '@store/meetings/selectors';
import { IMeetingFilter, IMeetingsState } from '@store/meetings/types';
import { SagaIterator } from 'redux-saga';
import { apply, select } from 'redux-saga/effects';
import { MeetingsFiltersService } from 'services/meetings-filters-service';

export class UpdateFilters {
  static get action() {
    return createAction<IMeetingFilter>('UPDATE_MAIN_FILTERS');
  }

  static get reducer() {
    return (draft: IMeetingsState, { payload }: ReturnType<typeof UpdateFilters.action>) => {
      if (draft.filters.isMyMeetingsOpened) {
        draft.filters.myMeetingsFilter = payload;
      } else {
        draft.filters.allMeetingsFilter = payload;
      }
    };
  }

  static get saga() {
    return function* ({ payload }: ReturnType<typeof UpdateFilters.action>): SagaIterator {
      const filtersService = new MeetingsFiltersService();

      const isOpenedMyMeetings = yield select(openedMyMeetingsSelector);

      yield apply(filtersService, filtersService.initializeOrUpdate, [
        isOpenedMyMeetings
          ? {
              allMeetingsFilters: filtersService?.filters?.allMeetingsFilters,
              myMeetingsFilters: payload,
            }
          : {
              myMeetingsFilters: filtersService?.filters?.myMeetingsFilters,
              allMeetingsFilters: payload,
            },
      ]);
    };
  }
}
