import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetTopicsByTagIdSuccess } from '@store/topics/features/get-topics-by-tagId/get-topics-by-tagId-success';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ITopic } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export class GetTopicsByTagId {
  static get action() {
    return createDeferredAction<number>('topics/GET_TOPICS_BY_TAG_ID');
  }

  static get saga() {
    return function* ({ meta, payload }: ReturnType<typeof GetTopicsByTagId.action>): SagaIterator {
      try {
        const { data } = GetTopicsByTagId.httpRequest.call(
          yield call(() => GetTopicsByTagId.httpRequest.generator(payload)),
        );

        meta?.deferred.resolve(data);
        yield put(GetTopicsByTagIdSuccess.action(data));
      } catch (e: any) {
        meta?.deferred.reject();
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<ITopic[]>, number>(
      (tagId) => replaceInUrl(MAIN_API.GET_TOPICS_BY_TAG_ID, ['tagId', tagId]),
      HttpRequestMethod.Get,
    );
  }
}
