import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { IUnblockUserPayload } from '@store/users/types';
import { replaceInUrl } from '@utils/replace-in-url';
import { AxiosResponse } from 'axios';
import { SagaIterator } from 'redux-saga';
import { call } from 'redux-saga/effects';

export class UnblockUser {
  static get action() {
    return createDeferredAction<number>('users/UNBLOCK_USER');
  }

  static get saga() {
    return function* ({ payload, meta }: ReturnType<typeof UnblockUser.action>): SagaIterator {
      try {
        UnblockUser.httpRequest.call(
          yield call(() => UnblockUser.httpRequest.generator({ userIdToUnblock: payload })),
        );

        meta?.deferred.resolve();
      } catch (e) {
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<undefined>, IUnblockUserPayload>(
      ({ userIdToUnblock }) =>
        replaceInUrl(MAIN_API.UNBLOCK_USER, ['userIdToUnblock', userIdToUnblock]),
      HttpRequestMethod.Delete,
    );
  }
}
