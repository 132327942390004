import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetMyStatisticsFailure } from '@store/profile/features/get-statistics/get-my-statistics-failure';
import { IProfileState } from '@store/profile/types';
import { AxiosResponse } from 'axios';
import { IStatistic } from 'lingopractices-models';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

import { GetMyStatisticsSuccess } from './get-my-statistics-success';

export class GetMyStatistics {
  static get action() {
    return createDeferredAction('profile/GET_MY_STATISTICS');
  }

  static get reducer() {
    return (draft: IProfileState) => {
      draft.requests.getMyStatisticsPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetMyStatistics.action>): SagaIterator {
      try {
        const { data } = GetMyStatistics.httpRequest.call(
          yield call(() => GetMyStatistics.httpRequest.generator()),
        );

        yield put(GetMyStatisticsSuccess.action(data));
        meta?.deferred.resolve(data);
      } catch (e) {
        yield put(GetMyStatisticsFailure.action());
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IStatistic>, undefined>(
      MAIN_API.GET_MY_STATISTICS,
      HttpRequestMethod.Get,
    );
  }
}
