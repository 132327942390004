import { createAction } from '@reduxjs/toolkit';
import { IUserBotsState } from '@store/user-bots/types';
import { ITelegramBotInfo } from 'lingopractices-models/src/main';

export class GetUserBotsSuccess {
  static get action() {
    return createAction<ITelegramBotInfo[]>('user_bots/GET_USER_BOTS_SUCCESS');
  }

  static get reducer() {
    return (draft: IUserBotsState, { payload }: ReturnType<typeof GetUserBotsSuccess.action>) => {
      draft.userBots = payload;

      return draft;
    };
  }
}
