import { GetBannersFailure } from '@store/banners/features/get-banners/get-banners-failure';
import { GetBannersSuccess } from '@store/banners/features/get-banners/get-banners-success';
import { IBannersState } from '@store/banners/types';
import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { AxiosResponse } from 'axios';
import { IBannerLocalized } from 'lingopractices-models/src/main';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';

export class GetBanners {
  static get action() {
    return createDeferredAction('banners/GET_BANNERS');
  }

  static get reducer() {
    return (draft: IBannersState) => {
      draft.requests.getBannersPending = true;

      return draft;
    };
  }

  static get saga() {
    return function* ({ meta }: ReturnType<typeof GetBanners.action>): SagaIterator {
      try {
        const { data } = GetBanners.httpRequest.call(
          yield call(() => GetBanners.httpRequest.generator()),
        );

        yield put(GetBannersSuccess.action(data));
        meta?.deferred.resolve(data);
      } catch (e) {
        yield put(GetBannersFailure.action());
        meta?.deferred.reject(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IBannerLocalized[]>, undefined>(
      MAIN_API.BANNERS,
      HttpRequestMethod.Get,
    );
  }
}
