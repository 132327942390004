import { createDeferredAction } from '@store/common/actions';
import { httpRequestFactory } from '@store/common/http-request-factory';
import { HttpRequestMethod } from '@store/common/http-request-method';
import { MAIN_API } from '@store/common/path';
import { GetLanguages } from '@store/languages/features/get-languages/get-languages';
import { GetLanguagesFailure } from '@store/languages/features/get-languages/get-languages-failure';
import { GetLanguagesSuccess } from '@store/languages/features/get-languages/get-languages-success';
import { languagesSelector } from '@store/languages/selectors';
import { getProfileDataSelector } from '@store/profile/selectors';
import { IProfileState } from '@store/profile/types';
import { AxiosError, AxiosResponse } from 'axios';
import { logError } from 'core/sentry';
import { ILanguage, IUpdateUserRequest, IUser, PushNotificationTypes } from 'lingopractices-models';
import { SagaIterator, Task } from 'redux-saga';
import { call, put, race, select, take } from 'redux-saga/effects';

import { UpdateProfileFailure } from './update-profile-failure';
import { UpdateProfileSuccess } from './update-profile-success';

export class UpdateProfile {
  static get action() {
    return createDeferredAction<IUpdateUserRequest>('profile/UPDATE_PROFILE');
  }

  static get reducer() {
    return (draft: IProfileState) => {
      draft.requests.updateProfilePending = true;
    };
  }

  static get saga() {
    return function* (action: ReturnType<typeof UpdateProfile.action>): SagaIterator {
      const {
        gender,
        interfaceLanguageId,
        languageLevel,
        practiceLanguageId,
        countryName,
        countryCode,
        timeZoneId,
        city,
        username,
        webAppPushNotificationTypes,
        aboutMe,
      } = action.payload;

      const languages: ILanguage[] = yield select(languagesSelector);

      const getLanguageNameById = (languagesArray: ILanguage[], id: string) => {
        const languageObj = languagesArray.find((lang) => lang.id === id);

        return languageObj ? languageObj.name : '';
      };

      const user: IUser = yield select(getProfileDataSelector);
      if (
        user.webAppPushNotificationTypes === PushNotificationTypes.None ||
        webAppPushNotificationTypes === PushNotificationTypes.None
      ) {
        logError(new Error('push = 0'));
      }

      try {
        UpdateProfile.httpRequest.call(
          yield call(() =>
            UpdateProfile.httpRequest.generator({
              ...action.payload,
              username: username || user.username,
            }),
          ),
        );

        if (user.interfaceLanguage.id !== interfaceLanguageId) {
          yield put(GetLanguages.action({ locale: interfaceLanguageId }));

          const { failure }: { failure: Task | undefined } = yield race({
            success: take(GetLanguagesSuccess.action),
            failure: take(GetLanguagesFailure.action),
          });

          if (failure) {
            action.meta?.deferred.reject({} as AxiosError);
            return;
          }
        }

        const practiceLanguage = practiceLanguageId
          ? {
              id: practiceLanguageId,
              name: getLanguageNameById(languages, practiceLanguageId),
            }
          : undefined;

        const profileInfo: IUser = {
          ...user,
          practiceLanguage,
          interfaceLanguage: {
            id: interfaceLanguageId,
            name: getLanguageNameById(languages, interfaceLanguageId),
          },
          id: user.id,
          gender,
          languageLevel,
          userGoogleInfo: user.userGoogleInfo,
          username: username || user.username,
          email: user.email,
          telegramUserId: user.telegramUserId,
          countryName,
          countryCode,
          city,
          timeZoneId,
          mobileAppPushNotificationTypes: user.mobileAppPushNotificationTypes,
          webAppPushNotificationTypes: webAppPushNotificationTypes as PushNotificationTypes,
          hasPushNotificationToken: user.hasPushNotificationToken,
          aboutMe: aboutMe || user.aboutMe,
        };

        yield put(UpdateProfileSuccess.action(profileInfo));
        action.meta?.deferred.resolve();
      } catch (e: any) {
        yield put(UpdateProfileFailure.action());
        action.meta?.deferred.reject(e);
        logError(e);
      }
    };
  }

  static get httpRequest() {
    return httpRequestFactory<AxiosResponse<IUser>, IUpdateUserRequest>(
      MAIN_API.UPDATE_USER,
      HttpRequestMethod.Put,
    );
  }
}
