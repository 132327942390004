import { createAction } from '@reduxjs/toolkit';
import { ITopicsState } from '@store/topics/types';

export class CreateTopicFailure {
  static get action() {
    return createAction('topics/CREATE_TOPIC_FAILURE');
  }

  static get reducer() {
    return (draft: ITopicsState) => {
      draft.requests.createTopicPending = false;
    };
  }
}
