const ROOT_API =
  import.meta.env.VITE_ENVIRONMENT === 'PRODUCTION'
    ? import.meta.env.VITE_PROD_URL
    : import.meta.env.VITE_DEV_URL;

export const MAIN_API = {
  SEARCH_MEETINGS: `${ROOT_API}/meetings/search`,
  JOIN_MEETING: `${ROOT_API}/meetings/join`,
  GOOGLE_REAUTH: `${ROOT_API}/user-provider-info/login?provider=:provider`,
  GET_MEETING_DAYS_BY_PREFERENCES: `${ROOT_API}/meetings/days`,
  SEARCH_TOPICS: `${ROOT_API}/topics/search`,
  CREATE_TOPIC: `${ROOT_API}/topics`,
  EDIT_TOPIC: `${ROOT_API}/topics/questions`,
  GET_TOPIC_BY_ID: `${ROOT_API}/topics/:topicId`,
  GET_QUESTIONS_BY_TOPIC_ID: `${ROOT_API}/topic/:topicId/questions`,
  SEARCH_TAGS: `${ROOT_API}/tags/search`,
  GET_TOPICS_BY_TAG_ID: `${ROOT_API}/topics/tag/:tagId`,
  UPDATE_USER: `${ROOT_API}/users`,
  GET_USER: `${ROOT_API}/users/my-profile`,
  SET_LOCATION: `${ROOT_API}/users/set-location`,
  GET_LANGUAGES: `${ROOT_API}/languages?locale=:locale`,
  GET_MEETING_BY_ID: `${ROOT_API}/meetings/:meetingId`,
  CREATE_MEETING: `${ROOT_API}/meetings`,
  GET_MY_MEETINGS: `${ROOT_API}/meetings/search-my-meetings`,
  LEAVE_MEETING: `${ROOT_API}/meetings/:meetingId/leave?userId=:userId`,
  NOTIFICATIONS_PREFERENCES: `${ROOT_API}/notification-preferences`,
  DELETE_NOTIFICATIONS_PREFERENCES: `${ROOT_API}/notification-preferences/:id`,
  GET_CREATE_MEETING_CONTENT: `${ROOT_API}/content/messages/create-meeting?locale=:locale`,
  CHECK_GOOGLE_VISIT: `${ROOT_API}/sign-in-requests/:requestId`,
  GET_CHAT: `${ROOT_API}/meeting-chat-messages/search`,
  CREATE_MESSAGE: `${ROOT_API}/meeting-chat-messages`,
  EDIT_MEETING_NOTIFICATION: `${ROOT_API}/meetings/edit-notification`,
  CHECK_NICKNAME: `${ROOT_API}/users/:nickname`,
  SEND_FEEDBACK: `${ROOT_API}/feedbacks`,
  EDIT_MEETING: `${ROOT_API}/meetings`,
  GET_STATISTICS_BY_ID: `${ROOT_API}/users/:id/statistic`,
  GET_MY_STATISTICS: `${ROOT_API}/users/statistic`,
  NOTIFICATIONS_SCHEDULE: `${ROOT_API}/notification-schedule`,
  SUBSCRIBE_NOTIFICATIONS: `${ROOT_API}/push-notifications/subscribe`,
  OPEN_AI_TOPIC_QUESTIONS: `${ROOT_API}/openai/topic-questions`,
  BANNERS: `${ROOT_API}/banners/my`,
  MARK_BANNER_WATCHED: `${ROOT_API}/banners/:bannerId/mark-as-watched`,
  FETCH_AVAILABLE_SUBSCRIPTIONS: `${ROOT_API}/products/subscriptions`,
  GET_MEETING_PACKAGES: `${ROOT_API}/products/meeting-packages`,
  GET_PAYMENT_LINK_YOOKASSA: `${ROOT_API}/yookassa`,
  CHECK_PAYMENT_ID_YOOKASSA: `${ROOT_API}/yookassa/:paymentId`,
  DISABLE_AUTO_WITHDRAWAL_YOOKASSA: `${ROOT_API}/yookassa/disable-auto-withdrawal`,
  GET_PAYMENT_LINK_TELEGRAM: `${ROOT_API}/tg/payment`,
  BLOCK_USER: `${ROOT_API}/BlackList/:userId`,
  UNBLOCK_USER: `${ROOT_API}/BlackList/:userIdToUnblock`,
  USER_BOTS: `${ROOT_API}/user-bots`,
  GET_CURRENT_USER_BOT: `${ROOT_API}/user-bots/current`,
  UPLOAD_IMAGE: `${ROOT_API}/images`,
};

export const ABSTRACT_API = `https://ipgeolocation.abstractapi.com/v1/?api_key=${
  import.meta.env.VITE_ABSTRACT_API_KEY
}`;

const MAIN_GOOGLE_API = 'https:/maps.googleapis.com/maps/api/';

export const GOOGLE_API = {
  GET_TIMEZONE: `${MAIN_GOOGLE_API}timezone/json?location=:lat%2C:lng&timestamp=:timeStamp&key=:apiKey`,
  GET_ADDRESS_BY_LAT_LNG: `${MAIN_GOOGLE_API}geocode/json?latlng=:lat,:lng&key=:apiKey`,
};
