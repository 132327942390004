import { GetMeetingPackages } from '@store/purchase/get-meeting-packages/get-meeting-packages';
import { GetPaymentLinkTelegram } from '@store/purchase/get-payment-link-telegram/get-payment-link-telegram';
import { all, takeLatest } from 'redux-saga/effects';

import { DisableAutoWithdrawalYookassa } from './disable-auto-withdrawal-yookassa/disable-auto-withdrawal-yookassa';
import { FetchAvailablePurchases } from './fetch-available-purchases/fetch-available-purchases';
import { FinishPurchaseYookassa } from './payment-link-yookassa/finish-purchase-yookassa';
import { GetPaymentLinkYookassa } from './payment-link-yookassa/get-payment-link-yookassa';

export function* purchaseSagas() {
  yield all([takeLatest(FetchAvailablePurchases.action, FetchAvailablePurchases.saga)]);
  yield all([takeLatest(GetPaymentLinkYookassa.action, GetPaymentLinkYookassa.saga)]);
  yield all([takeLatest(FinishPurchaseYookassa.action, FinishPurchaseYookassa.saga)]);
  yield all([takeLatest(DisableAutoWithdrawalYookassa.action, DisableAutoWithdrawalYookassa.saga)]);
  yield all([takeLatest(GetPaymentLinkTelegram.action, GetPaymentLinkTelegram.saga)]);
  yield all([takeLatest(GetMeetingPackages.action, GetMeetingPackages.saga)]);
}
